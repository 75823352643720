import { ActionButton, BaseTable, Button, Loading } from "components"
import moment from "moment"
import { TaskList } from "pages/Task/types"
import { useTaskList } from "pages/Task/utils/hooks"
import { TASK_STATUS } from "pages/Task/utils/vars"
import { Fragment, useState } from "react"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { useToggle } from "utils"
import { ModalDelete } from "../../ModalDelete"

type TableTaskListProps = {
  tasks: ReturnType<typeof useTaskList>
}

export const TableTaskList = ({ tasks }: TableTaskListProps) => {
  const navigate = useNavigate()
  const modalDelete = useToggle(false)
  const [deleteData, setDeleteData] = useState<TaskList>({} as TaskList)

  return (
    <Fragment>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>NO</th>
            <th colSpan={2}>Task Id</th>
            <th>Departement</th>
            <th>Date</th>
            <th>%</th>
            <th>Subject</th>
            <th>Status</th>
            <th>Owner Task</th>
            <th rowSpan={2}>Action</th>
          </tr>
        </thead>

        <tbody>
          {tasks.data?.length && !tasks.isLoading ? (
            tasks.data.map((item, key) => {
              return (
                <tr key={key}>
                  <td className="text-center">{key + 1}</td>
                  <td className="text-center px-0">
                    <Button
                      size="xs"
                      color="ghost"
                      onClick={() => {
                        navigate(`/tasks/edit/${item.task_id}`)
                      }}>
                      <IoSendSharp className="size-4" />
                    </Button>
                  </td>
                  <td> {item.trx_code} </td>
                  <td>{item.department_name}</td>
                  <td>{moment(item.date_end).format("DD/MM/YYYY")}</td>
                  <td></td>
                  <td>{item.subject}</td>
                  <td className="text-center">
                    {TASK_STATUS[item.task_status]}{" "}
                  </td>
                  <td>{item.owner_name}</td>
                  <td>
                    <div className="flex justify-center items-center">
                      <ActionButton.Delete
                        onClick={() => {
                          modalDelete.setActive(true)
                          setDeleteData(item)
                        }}
                      />
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={12} className="text-center">
                <Loading
                  loading={tasks.isLoading}
                  errorText={tasks.isLoading ? "" : "No data available"}
                />
              </td>
            </tr>
          )}
        </tbody>
      </BaseTable>

      <ModalDelete
        data={deleteData}
        modalState={modalDelete}
        onSuccess={() => tasks.refetch()}
      />
    </Fragment>
  )
}
