import { ActionButton, Input } from "components"
import { Fragment, useRef, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { AttachmentType } from "../types"
import { PicturesSection } from "./PictureSection"

export const FormAttachments = () => {
  const [file, setFile] = useState<AttachmentType | null>(null)
  const attachmentRef = useRef<HTMLInputElement>(null)
  const methods = useFormContext()
  const attachments = useWatch({
    control: methods.control,
    name: "attachments"
  })

  const handleClickAttachment = () => {
    if (attachmentRef?.current && !file) {
      attachmentRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const url = URL.createObjectURL(file)

      setFile({
        file,
        url,
        name: file?.name
      })
    }
  }

  const onAppendAttachment = () => {
    methods.setValue(
      "attachments",
      methods.getValues().attachments.concat(file)
    )

    setFile(null)
  }

  return (
    <Fragment>
      <section className="flex flex-col justify-start items-start gap-3">
        <section className="flex justify-start items-start gap-3">
          <div className="hidden">
            <input
              ref={attachmentRef}
              type="file"
              placeholder="Attachment"
              onChange={handleFileChange}
            />
          </div>

          <div className="flex justify-center items-center">
            <Input
              key={file?.url}
              onClick={handleClickAttachment}
              label="Attachments"
              placeholder="Choose file"
              value={file?.name}
              onChange={(e) => {
                if (file) {
                  setFile((prev) => ({
                    file: prev?.file,
                    url: prev?.url ?? "",
                    name: e.target.value
                  }))
                }
              }}
            />

            <ActionButton.Add
              className="mt-9 mx-3 bg-primary"
              onClick={onAppendAttachment}
            />
          </div>
        </section>

        {attachments?.length ? (
          <div className="max-h-[400px] w-full">
            <PicturesSection
              items={1}
              dataPicture={attachments}
              className="h-full w-full"
            />
          </div>
        ) : null}

        <section className="mt-3">
          <ol style={{ listStyle: "decimal" }}>
            {attachments?.map((attachment: AttachmentType) => (
              <li className="mb-2" key={attachment?.url}>
                {attachment?.name}
              </li>
            ))}
          </ol>
        </section>
      </section>
    </Fragment>
  )
}
