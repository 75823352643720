import { Fragment } from "react"
import { Loading } from "../../../../../../components"
import { Item } from "./components/Item"
import { ListBank } from "../../types"

export function ChildItem(props: {
  data: ListBank[]
  isLoading: boolean
}) {
  

  return (
    <Fragment>
      {props.isLoading || props.data.length === 0 ? (
        <tr>
          <td colSpan={9} className="text-center">
            <Loading loading={props.isLoading} errorText="No data available" />
          </td>
        </tr>
      ) : props.data.map((item, key) => (
        <Item index={key} item={item} data={props.data} isLoading={props.isLoading} />
      ))}
    </Fragment>
  )
}