import { useContext, useState } from "react"
import { BaseTable, Card, FooterMenu, TableHeaderSort } from "../../../../components"
import { ChildItem } from "./components"
import { Render, RenderProvider, useList } from "./utils"
import { ListBank } from "./types"

type headerState = { title: string, sort: boolean, align?: 'left' | 'right' }

export default function CoaGroup() {
  const [fillSort, setFillSort] = useState<string>('')
  const { data, isLoading } = useList({ sort: fillSort });

  const header: headerState[] = [
    { title: "CODE", sort: false },
    { title: "COA GROUP", sort: false },
    { title: "REPORT TYPE", sort: false },
    { title: "DESCRIPTION", sort: false },
  ]

  const sortMapping: Record<string, string> = {
    'CODE': 'code',
    'COA GROUP': 'coagroup',
    'REPORT TYPE': 'reporttype',
    'DESCRIPTION': 'description',
  }
  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setFillSort(sortParam)
  }

  return (
    <RenderProvider>
      <section className="container pt-5  gap-5 flex flex-col">
        <Card>
          <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
            <div className="bg-black p-3 text-white rounded-lg">COA GROUP</div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
              <BaseTable className="table">
                <thead>
                  <tr>
                    <th>NO</th>
                    <TableHeaderSort
                      title={header}
                      onSort={(item, sortType) => handleSort(item, sortType)}
                    />
                  </tr>
                </thead>
                <tbody>
                  <TableContent data={data} isLoading={isLoading} />
                </tbody>
              </BaseTable>
          </Card.Body>
        </Card>
        <FooterMenu/>
      </section>
    </RenderProvider>
  )
}
function TableContent(props: {
  data: ListBank[]
  isLoading: boolean
}) {
  const { render } = useContext(Render)

  if (render) {
    return <ChildItem data={props.data} isLoading={props.isLoading}/>
  } else {
    return <div />
  }
}