import { Fragment} from "react"
import type { ProductList } from "../../../../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi } from "utils"

interface IsActiveProps {
    id: ProductList
    refetch?: () => void
    permission?: boolean
}

export const IsActive = ({ id, refetch, permission }: IsActiveProps) => {
    const api = useApi()
    const onSubmit = (value: ProductList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put("/product/changestatus", { product_id: value.product_id, productdel: !value.is_deleted }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            )
            .then(() => refetch && refetch())
            .catch(() => {})
            .finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle permission="IR015" isChecked={id.is_deleted} onClick={() => onSubmit(id)} />
        </Fragment>
    )
}