// Pages
import CountInventory from "pages/Inventori/Transaction/CountInventory"
import InventoriRegister from "pages/Inventori/Register"
import Journal from "pages/Finance/Journal"
import Find from "pages/FindSection"
import TransactionJournal from "pages/Inventori/Transaction/TransactionJournal"
import OpeningStock from "pages/Inventori/Transaction/OpeningStock"
import ApproveTransfer from "pages/Inventori/Transaction/ApproveTransfer"
import {
  CreatePage,
  CreatePageTwo
} from "../pages/Inventori/Transaction/AddNewItem/components"
import {
  DetailPage,
  UpdatePage
} from "../pages/Inventori/Transaction/AddNewItem/components/ChildItem/components/Item/components"
import {
  UpdateAction,
  CreateAction
} from "pages/Inventori/Transaction/OpeningStock/components/ActionSection"
import TransferItems from "pages/Inventori/Transaction/TransferItem"
import TransferItemsEdit from "pages/Inventori/Transaction/TransferItem/Edit"
import TransferList from "pages/Inventori/Transaction/TransferItem/List"
import Received from "pages/Inventori/Transaction/Received"
import ReceivedEdit from "pages/Inventori/Transaction/Received/Edit"
import ApproveReceived from "pages/Inventori/Transaction/ApproveReceived"
import ApproveAdjustment from "pages/Inventori/Transaction/ApproveAdjustment"
import AdjustmentInventory from "pages/Inventori/Transaction/AdjustmentInventory"


// Types
import type { RouteType } from "types"
import ApprovalOpeningStock from "pages/Inventori/Transaction/ApprovalOpeningStock"

export const inventoryRoutes: RouteType[] = [
  {
    path: "/inventory/register",
    element: <InventoriRegister />,
    permission: "IR"
  },
  {
    path: "/inventory/transaction/add-new-item",
    element: <CreatePage />,
    permission: "IT01"
  },
  {
    path: "/inventory/transaction/add-new-items",
    element: <CreatePageTwo />,
    permission: "IT01"
  },
  {
    path: "/inventory/transaction/add-new-item/create",
    element: <CreatePage />,
    permission: "IT011"
  },
  {
    path: "/inventory/transaction/add-new-item/update",
    element: <UpdatePage />,
    permission: "IR013"
  },
  {
    path: "/inventory/transaction/add-new-item/detail",
    element: <DetailPage />,
    permission: "IR012"
  },
  {
    path: "/inventory/transaction/transaction-jounal",
    element: <TransactionJournal />,
    permission: "IT07"
  },
  {
    path: "/inventory/transaction/opening-stock",
    element: <OpeningStock />,
    permission: "IT08"
  },
  {
    path: "/inventory/transaction/opening-stock/create",
    element: <CreateAction />,
    permission: "IT081"
  },
  {
    path: "/inventory/transaction/opening-stock/update/:opening_stock_id",
    element: <UpdateAction />,
    permission: "IT083"
  },
  {
    path: "/inventory/transaction/transfer-items",
    element: <TransferItems />,
    permission: "IT10"
  },
  {
    path: "/inventory/transaction/transfer-items/edit/:transfer_id",
    element: <TransferItemsEdit />,
    permission: "IT103"
  },
  {
    path: "/inventory/transaction/approve-transfer",
    element: <ApproveTransfer />,
    permission: "IT11"
  },
  {
    path: "/inventory/transaction/approve-received",
    element: <ApproveReceived />,
    permission: "IT12"
  },
  {
    path: "/inventory/transaction/received/transfer-list",
    element: <TransferList />,
    permission: "IT13"
  },
  {
    path: "/inventory/transaction/received/:transfer_id",
    element: <Received />,
    permission: "IT13"
  },
  {
    path: "/inventory/transaction/received/edit/:received_id",
    element: <ReceivedEdit />,
    permission: "IT133"
  },
  {
    path: "/inventory/transaction/approve-adjustment",
    element: <ApproveAdjustment />,
    permission: "IT14"
  },
  {
    path: "/inventory/transaction/approve-opening-stock",
    element: <ApprovalOpeningStock />,
    permission: "IT15"
  },
  {
    path: "/inventory/transaction/count-inventory",
    element: <CountInventory />,
    permission: "IT06" // BELUM
  },
  {
    path: "/inventory/transaction/adjustment-inventory",
    element: <AdjustmentInventory />,
    permission: "IT06" // BELUM
  },
  {
    path: "/inventory/journal",
    element: <Journal />,
    // permission: "IJ"
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/find",
    element: <Find />,
    // permission: "IF"
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/item-register",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/set-item-price",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/built-inventori",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/transfer-inventory",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/transaction/dashboard",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/list-item-summary",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/list-item-detail",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/list-item-transaction",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/analyse-inventory-summary",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/analyse-inventory-detail",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/build-component",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/inventory-count-sheet",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/pricing-summary",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/pricing-analyse",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/inventory-journal",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/stock-alert",
    element: <div />,
    permission: "IT" // BELUM
  },
  {
    path: "/inventory/report/dashboard",
    element: <div />,
    permission: "IT" // BELUM
  }
]
