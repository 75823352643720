// React
import { useContext } from "react"

// Components
import { Button, Card, FooterMenu, HeaderMenu } from "components"
import { ChildItem } from "./components"

// Utils
import { Render, RenderProvider } from "./utils"

export default function LinkAccount() {
  return (
    <RenderProvider>
     <section className="container pt-5  gap-5 flex flex-col">
        <HeaderMenu title="SETUP GENERAL LINK ACCOUNT">
          {/* <a
            href="#"
            target="_blank"
          > */}
            <Button color="primary">TUTORIAL</Button>
          {/* </a> */}
        </HeaderMenu>

        <Card>
          <Card.Body>
            <div className="overflow-x-auto">
            <TableContent/>
            </div>
          </Card.Body>
        </Card>
        <FooterMenu/>
      </section>
    </RenderProvider>
  )
}

function TableContent() {
  const { render } = useContext(Render)

  if (render) {
    return <ChildItem />
  } else {
    return <div />
  }
}