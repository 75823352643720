import { SubmitTaskActionType, TaskActionType } from "../../types"

export const submitTaskActionMap = (
  data: TaskActionType
): SubmitTaskActionType => {
  return {
    task_action_id: data.task_action_id,
    action_task_id: data.action_task_id,
    assign_to_id: data.assign_to,
    date_end: data.date_end,
    date_start: data.date_start,
    description: data.description,
    status: data.status,
    task_id: data.task_id,
    trx_code: data.trx_code
  }
}
