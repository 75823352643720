/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Label, Modal, Select } from "components"
import { CreatePage as NewCustomer } from "pages/Card/Transaction/CreateNewCustomer/components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Fragment, useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { TbPlus } from "react-icons/tb"
import { useToggle } from "utils"
import { FormSalesOrderType } from "../types"
import { generateCustomerShipped } from "../utils/functions"
import { useDropdownCustomer } from "../utils/hooks"

export type DropdownCustomerProps = {
  approvalView?: boolean
  isEdit?: boolean
}

export const DropdownCustomer = ({ approvalView, isEdit }: DropdownCustomerProps) => {
  const { dropdown, isLoading, refetch } = useDropdownCustomer()
  const { control, setValue, clearErrors } =
    useFormContext<FormSalesOrderType>()
  const modalCreateCustomer = useToggle(false)
  const [approval_status, customer_id] = useWatch({
    control: control,
    name: ["approval_status", "customer_id"]
  })

  useEffect(() => {
    if (customer_id && dropdown.length) {
      const customer = dropdown.find((c) => c.value === customer_id.toString())

      setValue("price_level_name", customer?.price_level_name ?? "")
      setValue(
        "payment_term_name",
        customer?.payment_term_name?.toString() ?? "0"
      )
    }
  }, [customer_id, dropdown])

  return (
    <>
      <Label text="CUSTOMER" />
      <section className="flex gap-3">
        <Controller
          control={control}
          name="customer_id"
          render={({ field, fieldState }) => (
            <Select
              className="grow"
              placeholder="Select Customer"
              isLoading={isLoading}
              options={dropdown}
              value={
                field.value
                  ? dropdown.find((c) => field.value.toString() === c.value)
                  : null
              }
              isDisabled={approval_status !== APPROVAL.PENDING || isEdit || approvalView}
              onChange={(e: any) => {
                field.onChange(e?.value)

                const shipped = generateCustomerShipped(e)
                if (shipped.length) {
                  clearErrors("shipped")
                }
                setValue("shipped", shipped)
                setValue("customer_name", e.label)
                setValue("payment_term", e.payment_term_id)
                setValue("price_level_name", e.price_level_name)
                setValue(
                  "payment_term_name",
                  (e.payment_term_name ?? 0).toString()
                )
                setValue("sodetail", [])
              }}
              error={fieldState.error?.message}
            />
          )}
        />

        <Button
          color="primary"
          className={`px-2 ${approvalView ? "hidden" : ""}`}
          onClick={modalCreateCustomer.toggle}>
          <TbPlus size={24} />
        </Button>
      </section>

      <ModalCreateCustomer
        isOpen={modalCreateCustomer.isActive}
        onClose={modalCreateCustomer.toggle}
        refetch={refetch}
      />
    </>
  )
}

type ModalCreateCustomerProps = {
  isOpen: boolean
  onClose?: () => void
  refetch: () => void
}

function ModalCreateCustomer({ isOpen, onClose, refetch }: ModalCreateCustomerProps) {

  useEffect(() => {
    if (!isOpen) {
      refetch()
    }
  }, [isOpen])

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        title="Create New Customer"
        size="full"
        closeModal={onClose}>
        <Modal.Body>
          <NewCustomer asModal />
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
