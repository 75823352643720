/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, DateInput, Label, Modal, Textarea } from "components"
import moment from "moment"
import { DropdownEmployee } from "pages/Sales/Transaction/CustomerOrder/components"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import toast from "react-hot-toast"
import { today, useApi, useToggle } from "utils"
import {
  TaskActionList,
  taskActionSchema,
  TaskActionType,
  TaskList
} from "../types"
import { submitTaskActionMap } from "../utils/functions"
import { useTaskActionCode, useTaskActionList } from "../utils/hooks"
import { DropdownActionStatus } from "./DropdownActionStatus"
import { DropdownActionTask } from "./DropdownActionTask"
import { FormAttachments } from "./FormAttachments"

type ModalActionLineProps = {
  data: TaskActionList | null
  task: Partial<TaskList>
  modalState: ReturnType<typeof useToggle>
  taskAction: ReturnType<typeof useTaskActionList>
}

export const ModalActionLine = ({
  data,
  task,
  modalState,
  taskAction
}: ModalActionLineProps) => {
  const api = useApi()
  const [loadingData] = useState(false)
  const defaultValue: TaskActionType = useMemo(() => {
    return {
      task_action_id: data?.task_action_id,
      action_task_id: data?.action_task_id ?? NaN,
      assign_to: data?.assign_to_id ?? NaN,
      date_start: data?.date_start ?? today(),
      date_end: data?.date_end ?? today(),
      description: data?.description ?? "",
      status: data?.status ?? 1,
      task_id: task?.task_id ?? NaN,
      trx_code: data?.trx_code ?? "",
      attachments: []
    }
  }, [task, data])

  const methods = useForm<TaskActionType>({
    defaultValues: defaultValue,
    resolver: yupResolver(taskActionSchema)
  })

  const [date_start, date_end, trx_code] = useWatch({
    control: methods.control,
    name: ["date_start", "date_end", "trx_code"]
  })

  useEffect(() => methods.reset(defaultValue), [defaultValue])

  const onSubmit = async (value: TaskActionType) => {
    const generatePromise = value?.task_action_id
      ? api.put(
          `/task_action/${value.task_action_id}`,
          submitTaskActionMap(value)
        )
      : api.post("/task_action", submitTaskActionMap(value))

    await toast
      .promise(generatePromise, {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {})
      .catch((err) => {
        throw new Error(err)
      })
      .finally(() => {
        methods.reset()
        taskAction.refetch()
        modalState.setActive(false)
      })
  }

  useTaskActionCode({
    trx_date: date_start,
    enabled: !data?.trx_code && task.task_id ? true : false,
    onSuccess: (data) => methods.setValue("trx_code", data)
  })

  return (
    <Modal
      title={`TASK | ACTION LINE | ${trx_code}`}
      isOpen={modalState.isActive}
      closeModal={modalState.toggle}>
      <Modal.Body>
        <FormProvider {...methods}>
          <ModalActionLineHead task={task} />
          <section className="grid grid-cols-2 gap-3">
            <section className="max-w-sm flex flex-col gap-1">
              <DropdownActionStatus />

              <div>
                <Label text="DATE START" />
                <DateInput
                  className="w-[40%]"
                  placeholderText="Date Start"
                  onChange={(date) =>
                    methods.setValue(
                      "date_start",
                      moment(date).format("YYYY-MM-DD")
                    )
                  }
                  selected={moment(date_start).toDate()}
                />
              </div>

              <div>
                <Label text="DATE END" />
                <DateInput
                  className="w-[40%]"
                  placeholderText="Date End"
                  onChange={(date) =>
                    methods.setValue(
                      "date_end",
                      moment(date).format("YYYY-MM-DD")
                    )
                  }
                  selected={moment(date_end).toDate()}
                />
              </div>

              <DropdownActionTask />

              <DropdownEmployee
                showCreate={false}
                label="ASSIGN TO"
                name="assign_to"
              />

              <Controller
                control={methods.control}
                name="description"
                render={({ field, fieldState }) => (
                  <Textarea
                    {...field}
                    label="DESCRIPTION"
                    defaultValue={field.value}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      field.onChange(e.target.value)
                    }}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </section>

            <FormAttachments />
          </section>
        </FormProvider>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="primary"
          disabled={loadingData}
          loading={methods.formState.isSubmitting ? "true" : undefined}
          onClick={() => {
            methods.handleSubmit((data) => onSubmit(data))()
          }}>
          SUBMIT
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ModalActionLineHead({ task }: { task: Partial<TaskList> }) {
  return (
    <div>
      <table className="uppercase font-bold my-3">
        <tbody>
          <tr>
            <td>TASK ID# </td>
            <td>: {task?.trx_code} </td>
          </tr>
          <tr>
            <td>SUBJECT</td>
            <td>: {task?.subject} </td>
          </tr>
          <tr>
            <td>DATE</td>
            <td>
              {": "} {moment(task?.date_start).format("DD/MM/YYYY")} TO{" "}
              {moment(task?.date_end).format("DD/MM/YYYY")}{" "}
            </td>
          </tr>
          <tr>
            <td>OWNER TASK</td>
            <td>: {task.owner_name}</td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  )
}
