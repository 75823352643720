/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext, useWatch } from "react-hook-form"
import { today } from "utils"
import { TaskType } from "../types"
import { useTaskCode } from "../utils/hooks"

export const CodeTask = ({ isEdit }: { isEdit: boolean }) => {
  const { control, formState, setValue } = useFormContext<TaskType>()
  const transaction_date = today()
  const [trx_code, date_start] = useWatch({
    control,
    name: ["trx_code", "date_start"]
  })

  useTaskCode({
    key: formState.submitCount,
    trx_date: date_start,
    onSuccess: (data) => {
      setTimeout(() => setValue("trx_code", data), 1000)
    },
    enabled: !isEdit && Boolean(transaction_date)
  })

  return <div>{trx_code}</div>
}
