import { Fragment } from "react"
import { ChildItem } from "../.."
import type { ListBank } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"

export function Item(props: { item: ListBank, index: number, data: ListBank[], isLoading: boolean}) {
  const { isActive } = useToggle(false)

  return (
    <Fragment>
      <tr>
        <td>{props.index + 1}</td>
        <td>{props.item.group_coa_code}</td>
        <td>{props.item.group_coa_name}</td>
        <td>{props.item.group_coa_type === 1 ? "BALANCE SHEET" : props.item.group_coa_type === 2 ? "PROFIT AND LOST" : "-"}</td>
        <td>{props.item.description}</td>
      </tr>
      {isActive && <ChildItem data={props.data} isLoading={props.isLoading}  />}
    </Fragment>
  )
}