import { useContext } from "react" 
import { Card, FooterMenu } from "../../../../components" 
import { Render, RenderProvider } from "./utils" 
import { ChildItem } from "./components"

export default function LinkAccountPurchase() {
    return (
        <RenderProvider>
            <section className="container pt-5  gap-5 flex flex-col">
                <Card>
                    <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
                        <div className="bg-black p-3 text-white rounded-lg">LINK ACCOUNT PURCHASE</div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <div className="overflow-x-auto">
                            <TableContent />
                        </div>
                    </Card.Body>
                </Card>
                <FooterMenu/>
            </section>
        </RenderProvider>
    )
}

function TableContent() {
    const { render } = useContext(Render)

    if (render) {
        return <ChildItem />
    } else {
        return <div />
    }
}