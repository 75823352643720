import { Fragment, useState } from "react"
import { ActionButton, Button, Card, FooterMenu, HeaderMenu, Loading } from "../../../../../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import type { AddressList, BankList, EducationList, PersonalList, ProfileList, WorkStatusList } from "../../../../../types"
import { useLocation, useNavigate } from "react-router-dom"
import { useDetailProfileData, useDetailAddressData, useDetailWorkStatusData, useDetailEducationData, useDetailBankData, useDetailPrivillegeData } from "../utils"
import { RenderProvider } from "../../../../../utils"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApi } from "../../../../../../../../../utils"
import toast from "react-hot-toast"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { FormAddress } from "../../../../FormSection/FormAddress"
import { FormWorkStatus } from "../../../../FormSection/FormWorkStatus"
import { FormEducation } from "../../../../FormSection/FormEducation"
import { FormBank } from "../../../../FormSection/FormBank"
import { FormPrivilege } from "../../../../FormSection/FormPrivilege"
import { addressValidationSchema, bankValidationSchema } from "../../../../../utils/function"

export function Update(props: { id: PersonalList; permission?: boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <ActionButton.Update permission={"CR013"} className="join-item" onClick={() => navigate("/cards/transaction/create-new-employee/update", { state: { dataId: props.id } })} />
    </Fragment>
  )
}
export function UpdatePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const id = location?.state?.dataId?.employee_id
  const data = location?.state?.dataId
  const tabs = ["PROFILE", "ADDRESS", "WORK STATUS", "EDUCATION", "BANK", "PRIVILEGE"];

  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  
  const { dataProfile, errorProfile, isLoadingProfile, refetchProfile } = useDetailProfileData(id ? id : null)
  const { dataAddress, errorAddress, isLoadingAddress, refetchAddress } = useDetailAddressData(id ? id : null)
  const { dataWorkStatus, errorWorkStatus, isLoadingWorkStatus, refetchWorkStatus } = useDetailWorkStatusData(id ? id : null)
  const { dataEducation, errorEducation, isLoadingEducation, refetchEducation } = useDetailEducationData(id ? id : null)
  const { dataBank, errorBank, isLoadingBank, refetchBank } = useDetailBankData(id ? id : null)
  const { dataPrivillege, errorPrivillege, isLoadingPrivillege, refetchPrivillege } = useDetailPrivillegeData(id ? id : null)

  return (
    <RenderProvider>
      <section className="container my-5">
        <HeaderMenu title="UPDATE EMPLOYEE REGISTRATION" />

        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <Button
              type="button"
              className="!none mr-1" 
              color="primary"
              onClick={() => navigate("/cards/transaction/create-new-employee")}
            >
              CREATE EMPLOYEE
            </Button>
            <Button color="primary" onClick={() => navigate("/cards/register/all")}>
                BACK
            </Button>
          </Card.Body>
          <Card.Body>
            <div className="lg:flex gap-1">
              <div className="lg:w-[34%] border border-[#3c3c3c] lg:border-[#3c3c3c]">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PERSONAL DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex`}>
                  <div className="w-full p-6">
                    <DataForm dataDefault={data!} setActiveTab={setActiveTab} />
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%] border border-[#3c3c3c] lg:border-[#3c3c3c]">
                <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className="text-[11px]" />
                <div className={`max-w-sm w-full lg:max-w-full lg:flex`}>
                  <div className="w-full p-6">
                    {activeTab === 0 
                      ? isLoadingProfile || errorProfile 
                        ? <Loading errorText={errorProfile} loading={isLoadingProfile} /> 
                        : <DataProfileForm dataProfile={dataProfile!} id={id} refetchProfile={refetchProfile} />
                      : ""
                    }
                    {activeTab === 1
                      ? isLoadingAddress || errorAddress 
                        ? <Loading errorText={errorAddress} loading={isLoadingAddress} /> 
                        : <DataAddressForm dataAddress={dataAddress!} id={id} refetchAddress={refetchAddress} />
                      : ""
                    }
                    {activeTab === 2
                      ? isLoadingWorkStatus || errorWorkStatus 
                        ? <Loading errorText={errorWorkStatus} loading={isLoadingWorkStatus} /> 
                        : <DataWorkStatusForm dataWorkStatus={dataWorkStatus!} id={id} refetchWorkStatus={refetchWorkStatus} />
                      : ""
                    }
                    {activeTab === 3
                      ? isLoadingEducation || errorEducation 
                        ? <Loading errorText={errorEducation} loading={isLoadingEducation} /> 
                        : <DataEducationForm dataEducation={dataEducation!} id={id} refetchEducation={refetchEducation} />
                      : ""
                    }
                    {activeTab === 4
                      ? isLoadingBank || errorBank 
                        ? <Loading errorText={errorBank} loading={isLoadingBank} /> 
                        : <DataBankForm dataBank={dataBank!} id={id} refetchBank={refetchBank} />
                      : ""
                    }
                    {activeTab === 5
                      ? isLoadingPrivillege || errorPrivillege 
                        ? <Loading errorText={errorPrivillege} loading={isLoadingPrivillege} /> 
                        : <DataPrivilege dataPrivillege={dataPrivillege!} id={id} isLoadingPrivillege={isLoadingPrivillege} refetchPrivillege={refetchPrivillege} />
                      : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <section className="mt-2">
              <FooterMenu/>
        </section>
      </section>
    </RenderProvider>
  )
}
function DataForm(props: {
  dataDefault: PersonalList
  setActiveTab: (newState: any) => void
}) {
  const api = useApi()
  const location = useLocation()

  const [fileValue, setFileValue] = useState<File | undefined>(undefined);

  type UpdatePersonalData = Pick<PersonalList, "employee_code" | "empoyee_name" | "account" | "Employee_Foto"> & { passwd?: string, Confirm_Passwd?: string };

  const defaultValues:UpdatePersonalData = {
    employee_code: props?.dataDefault?.employee_code!,
    empoyee_name: props?.dataDefault.employee_name,
    account: props?.dataDefault.username,
    passwd: "",
    Confirm_Passwd: "",
    Employee_Foto: props?.dataDefault?.photo,
  }
  
  const validationSchema = yup.object().shape({ 
    employee_code: yup.string().label("Employee code").required(),
    empoyee_name: yup.string().label("Name").required().max(30, "Must be 30 characters or less"),
    account: yup
      .string()
      .label("Account")
      .required()
      .max(30, "Must be 30 characters or less"),
      // .test("checkDuplicate", "The account is already in use!", async (value) => api.post(`/employee/checkaccount?account=${value}&employee_id=0`)
      // .then((res) => res?.data?.status === "Error" ? false : true)
      // .catch(() => false)),
    passwd: yup.string().label("Password").optional().max(30, "Must be 30 characters or less"),
    Confirm_Passwd: yup
      .string()
      .label("Re-Password")
      .test('match', 'Passwords must match', function(value) { return value === this.parent.passwd ? true : false })
      .optional()
      .max(30, "Must be 30 characters or less"),
  })
  const methods = useForm<UpdatePersonalData>({ 
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: PersonalList) => {
    const formData = new FormData();
    
    formData.append('employee_code', value?.employee_code!);
    formData.append('empoyee_name', value?.empoyee_name);
    formData.append('account', value?.account);
    if(value?.passwd){formData.append('passwd', value?.passwd)};
    if(value?.Confirm_Passwd){formData.append('Confirm_Passwd', value?.Confirm_Passwd)};
    if(value?.Employee_Foto){formData.append(fileValue ? 'Employee_Foto' : "", value?.Employee_Foto)}

    const params: { [key: string]: string | undefined } = {};
    if (location?.state?.dataId?.employee_id) params.employee_id = location?.state?.dataId?.employee_id;

    return new Promise<void>((resolve) => {
      toast.promise(
        api.put("/employee/edit", formData, { params }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {
        props.setActiveTab(0)
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData action="UPDATE" onSubmit={onSubmit} setFileValue={setFileValue} />
    </FormProvider>
  )
}

interface ProfileProps {  dataProfile?: any; id: number, refetchProfile?: () => void }
const DataProfileForm: React.FC<ProfileProps> = ({ dataProfile, id, refetchProfile }) => {
  const api = useApi()
  type CreateProfile = Pick<ProfileList, "type_nationality" | "nomor" | "birthdate" | "marital_status" | "religion_id" | "handphone" | "email">;
  const checkDuplicateNumber = async (value: string | undefined) => {
    if (!value) return false;
    try {
      const response = await api.get(`/employee/cekidnumber?number=${value}&employee_id=${id}`);
      return response.data.payload;
    } catch (error) {
      return false;
    }
  };
  
  const defaultValues: CreateProfile = {
    type_nationality: dataProfile?.type_nationality ?? undefined,
    nomor: dataProfile?.nomor ?? "",
    birthdate: dataProfile?.birthdate ?? undefined,
    marital_status:  dataProfile?.marital_status ?? undefined,
    religion_id: dataProfile?.religion_id ?? undefined,
    handphone: dataProfile?.handphone ?? "",
    email: dataProfile?.email ?? ""
  }
  const profileValidationSchema = yup.object().shape({ 
    nomor: yup.string()
    .label("Number")
    .max(20, "Must be 20 characters or less")
    .test('is-required', 'Number is required field', function(value) {
      const { type_nationality } = this.parent;
      if (type_nationality && type_nationality.trim().length > 0) { return !!value }
      return true;
    })
    .test('checkDuplicateNumber', 'The number is already in use!', async function(value) {
      if (!value || value.trim() === "") { return true }
      const isUnique = await checkDuplicateNumber(value);
      return isUnique;
    }),
    handphone: yup.string().label("Handphone").optional().max(20, "Must be 20 characters or less"),
    email: yup.string().label("Email").optional().max(200, "Must be 200 characters or less").email("Email must be a valid email address"),
    type_nationality: yup.string().optional(),
    marital_status: yup.string().optional(),
    religion_id: yup.string().optional(),
    birthdate: yup.string().optional()
  })
  const methods = useForm<CreateProfile>({ 
    defaultValues,
    resolver: yupResolver(profileValidationSchema)
  })
  const onSubmit = (value: ProfileList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateprofile?employee_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchProfile && refetchProfile())
      .catch(() => refetchProfile && refetchProfile())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile action="UPDATE" onSubmit={onSubmit} refetchProfile={refetchProfile} />
    </FormProvider>
  )
}

interface AddressProps { 
  dataAddress?: any 
  id: number
  refetchAddress?: () => void
}
const DataAddressForm: React.FC<AddressProps> = ({ dataAddress, id, refetchAddress }) => {
  const api = useApi()

  const defaultValues = {
    country_id: dataAddress?.[0]?.country_id ?? undefined,
    province_id: dataAddress?.[0]?.province_id ?? undefined,
    regency_id: dataAddress?.[0]?.regency_id ?? undefined,
    subdistrict_id: dataAddress?.[0]?.subdistrict_id ?? undefined,
    village_id: dataAddress?.[0]?.village_id ?? undefined,
    address: dataAddress?.[0]?.address ?? undefined,
  }
  const methods = useForm<AddressList>({ 
    defaultValues,
    resolver: yupResolver(addressValidationSchema) 
  })
  const onSubmit = (value: AddressList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateaddress?employee_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchAddress && refetchAddress())
      .catch(() => refetchAddress && refetchAddress())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormAddress action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface WorkStatusProps { 
  dataWorkStatus?: any 
  id: number
  refetchWorkStatus?: () => void
}
const DataWorkStatusForm: React.FC<WorkStatusProps> = ({ dataWorkStatus, id, refetchWorkStatus }) => {
  const api = useApi()

  const defaultValues = { 
    employee_status_id: dataWorkStatus?.[0]?.employee_status_id ?? undefined,
    date_end: dataWorkStatus?.[0]?.date_end ?? undefined,
    date_start: dataWorkStatus?.[0]?.date_start ?? undefined,
    group_position_id: dataWorkStatus?.[0]?.group_position_id ?? undefined,
    job_position_id: dataWorkStatus?.[0]?.job_position_id ?? undefined,
    location_id: dataWorkStatus?.[0]?.location_id ?? undefined,
    department_id: dataWorkStatus?.[0]?.department_id ?? undefined,
    divisi_id: dataWorkStatus?.[0]?.divisi_id ?? undefined,
    gender: dataWorkStatus?.[0]?.gender ?? undefined,
    salary_type_id: dataWorkStatus?.[0]?.salary_type_id ?? undefined,
  }
  const methods = useForm<WorkStatusList>({ defaultValues })
  const onSubmit = (value: WorkStatusList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateworkstatus?employee_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchWorkStatus && refetchWorkStatus())
      .catch(() => refetchWorkStatus && refetchWorkStatus())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormWorkStatus action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface EducationProps { 
  dataEducation?: any 
  id: number
  refetchEducation?: () => void
}
const DataEducationForm: React.FC<EducationProps> = ({ dataEducation, id, refetchEducation }) => {
  const api = useApi()

  const defaultValues = {
    sd_id: dataEducation?.[0]?.sd_id ?? undefined,
    smp_id: dataEducation?.[0]?.smp_id ?? undefined,
    sma_id: dataEducation?.[0]?.sma_id ?? undefined,
    academy_id: dataEducation?.[0]?.academy_id ?? undefined,
    university_id: dataEducation?.[0]?.university_id ?? undefined,
    magister_id: dataEducation?.[0]?.magister_id ?? undefined,
    doctoral_id: dataEducation?.[0]?.doctoral_id ?? undefined,
  }
  const methods = useForm<EducationList>({ defaultValues })
  const onSubmit = (value: AddressList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateeducation?employee_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchEducation && refetchEducation())
      .catch(() => refetchEducation && refetchEducation())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormEducation action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface BankProps { 
  dataBank?: any 
  id: number
  refetchBank?: () => void
}
const DataBankForm: React.FC<BankProps> = ({ dataBank, id, refetchBank }) => {
  const api = useApi()

  const defaultValues = { 
    bank_id: dataBank?.[0]?.bank_id ?? undefined,
    branch: dataBank?.[0]?.branch ?? "",
    account_number: dataBank?.[0]?.account_number ?? "",
    account_name: dataBank?.[0]?.account_name ?? "",
  }
  const methods = useForm<BankList>({ 
    defaultValues,
    resolver: yupResolver(bankValidationSchema)
  })
  const onSubmit = (value: BankList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updatebank?employee_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => refetchBank && refetchBank())
      .catch(() => refetchBank && refetchBank())
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormBank action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}
interface PrivilegeProps { 
  dataPrivillege?: any 
  isLoadingPrivillege?: boolean
  id: number
  refetchPrivillege: () => void
}
const DataPrivilege: React.FC<PrivilegeProps> = ({ dataPrivillege, id, isLoadingPrivillege, refetchPrivillege }) => {
  const api = useApi()
  const [privillegeData, setPrivillegeData] = useState(dataPrivillege)

  const enabledFeatureIds: number[] = [];
  privillegeData.forEach((module: any) => { module.features.forEach((feature: any) => { if (feature.is_enabled) { enabledFeatureIds.push(feature.feature.feature_id) } }) });

  const onSubmit = () => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post(`/employee/access/${id}`, { feature_ids: enabledFeatureIds }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => {refetchPrivillege()})
      .catch(() => {refetchPrivillege()})
      .finally(resolve)
    })
  }

  return (
    <FormPrivilege 
      action="UPDATE" 
      privillegeData={privillegeData} 
      setPrivillegeData={setPrivillegeData} 
      onSubmit={onSubmit} 
      isLoadingPrivillege={isLoadingPrivillege}
      id={id}
      refetchPrivillege={refetchPrivillege}
    />
  )
}