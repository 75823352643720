import { useTaskActionList } from "pages/Task/utils/hooks"

type TableStatusActionLineProps = {
  taskAction: ReturnType<typeof useTaskActionList>
}

export const TableStatusActionLine = ({ taskAction }: TableStatusActionLineProps) => {

  const statusSummary = taskAction?.data?.reduce((acc, item) => {
    if (item.status === 1) {
      acc.notStarted += 1
    }
    if (item.status === 2) {
      acc.inProgress += 1
    }
    if (item.status === 3) {
      acc.completed += 1
    }
    if (item.status === 4) {
      acc.canceled += 1
    }
    return acc
  }, {
    notStarted: 0,
    inProgress: 0,
    completed: 0,
    canceled: 0
  })

  return (
    <table className="text-xs border-black border-[1px] text-black mt-3">
      <thead className="border-black border-[1px]">
        <tr className="uppercase text-center">
          <th className="border-black border-[1px] bg-notstarted">
            Not Started
          </th>
          <th className="border-black border-[1px] bg-inprogress">
            In Progress
          </th>
          <th className="border-black border-[1px] bg-completed">Completed</th>
          <th className="border-black border-[1px] bg-cancelled">Canceled</th>
        </tr>
      </thead>
      <tbody className="border-black border-[1px]">
        <tr className="text-center">
          <td className="border-black border-[1px] bg-notstarted">{statusSummary.notStarted}</td>
          <td className="border-black border-[1px] bg-inprogress">{statusSummary.inProgress}</td>
          <td className="border-black border-[1px] bg-completed">{statusSummary.completed}</td>
          <td className="border-black border-[1px] bg-cancelled">{statusSummary.canceled}</td>
        </tr>
      </tbody>
    </table>
  )
}