import { Fragment, useState } from "react"
import { ActionButton, Card, Loading, CommandButton, HeaderMenu } from "../../../../../../../../../components"
import type { BuyingList, ComponentList, ProductList, ProfileList, SellingList } from "../../../../../types"
import { RenderProvider } from "../../../../../utils"
import { useLocation, useNavigate } from "react-router-dom"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import { FormProvider, useForm } from "react-hook-form"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { 
  useCOGSManufacture, 
  useDetailBuyingData, 
  useDetailBuyingDataTable, 
  useDetailPersonalData, 
  useDetailPictureData, 
  useDetailProfileData, 
  useDetailSellingData, 
  useIngredientFormulaTable, 
  usePriceUnit 
} from "../utils"
import { FormBuying } from "../../../../FormSection/FormBuying"
import { FormComponent } from "../../../../FormSection/FormComponent"
import { FormSelling } from "../../../../FormSection/FormSelling"
import { FormPictures } from "../../../../FormSection/FormPictures"
import { FormPerformance } from "../../../../FormSection/FormPerformence"
import { FormTransaction } from "../../../../FormSection/FormTransaction"

interface DetailProps {
  id: ProductList 
  size: ProductList 
  permission?: boolean
}

export const Detail = ({ id, size, permission }: DetailProps) => {
  const navigate = useNavigate()
  const handleDetailClick = () => navigate("/inventory/transaction/add-new-item/detail", { state: { dataId: id, dataSize: size } })

  return (
    <Fragment>
      <ActionButton.Detail permission="IR012" className="join-item" onClick={handleDetailClick} /> 
    </Fragment>
  )
}
export function DetailPage() {
  const [activeTab, setActiveTab] = useState(0);
  const [disableTab, setDisableTab] = useState(false);
  const location = useLocation()
  const id = location?.state?.dataId?.product_id
  const size = location?.state?.dataSize
  const tabs = ["PROFILE", "BUYING", "COMPONENT", "SELLING", "LOCATION", "PERFORMENCE", "PICTURE", "TRANSACTION", "LOG"];
  
  const { dataDetail, dataError, isLoading } = useDetailPersonalData(id ? id : null)
  const { dataProfile, errorProfile, isLoadingProfile } = useDetailProfileData(id ? id : null)
  const { dataBuying, errorBuying, isLoadingBuying } = useDetailBuyingData(id ? id : null)
  const { dataList } = useDetailBuyingDataTable(id ? id : null)
  const { dataComponentTable, errorComponentTable, isLoadingComponentTable } = useIngredientFormulaTable(id ? id : null)
  const { dataCOGS } = useCOGSManufacture(id ? id : null)
  const { dataSelling, errorSelling, isLoadingSelling } = useDetailSellingData(id ? id : null)
  const { dataPicture, errorPicture, isLoadingPicture } = useDetailPictureData(id ? id : null)
  const { dataPriceUnit } = usePriceUnit(id ? id : null)
  
  const [itemsProfile, setItemsProfile] = useState<any | undefined>({ buy: "", sell: "", inventory: "", action: "" });

  return (
    <RenderProvider>
      <section className="container my-2">
        <HeaderMenu title="DATA ENTRY | CREATE NEW ITEM" />
        <Card>
          <Card.Body>
            <div className="grow text-xl">
              Item Name: <span className="font-bold">{dataDetail?.product_name}</span>
            </div>

            <div className="lg:flex gap-2">
              <div className="lg:w-[34%] border border-[#dadada] dark:border-[#3c3c3c]">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full text-white text-[12px]"><b>PRODUCT DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex`}>
                  <div className="w-full p-6">
                  {isLoading || dataError 
                    ? <Loading errorText={dataError} loading={isLoading} />
                    : <DataForm 
                        data={dataDetail!} 
                        dataDefault={size!} 
                        id={dataDetail?.product_id!} 
                        setActiveTab={setActiveTab} 
                        setDisableTab={setDisableTab} 
                        setItemsProfile={setItemsProfile} 
                        itemsProfile={itemsProfile}
                    />
                  }
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%] border border-[#dadada] dark:border-[#3c3c3c]">
                <Tab tabs={tabs} activeTab={activeTab} disabled={disableTab} setActiveTab={setActiveTab} dataProfile={dataProfile!} className="text-[11px]" />
                <div className={`max-w-sm w-full lg:max-w-full lg:flex`}>
                  <div className="w-full p-6">
                    {activeTab === 0 
                      ? isLoadingProfile || errorProfile 
                        ? <Loading errorText={errorProfile} loading={isLoadingProfile} /> 
                        : <DataProfileForm dataProfile={dataProfile!} setItemsProfile={setItemsProfile} itemsProfile={itemsProfile} />
                      : ""
                    }
                    {activeTab === 1 
                      ? isLoadingBuying || errorBuying 
                        ? <Loading errorText={errorBuying} loading={isLoadingBuying} /> 
                        : <DataBuyForm dataBuying={dataBuying!} dataList={dataList!} />
                      : ""
                    }
                    {activeTab === 2
                      ? isLoadingComponentTable || errorComponentTable 
                        ? <Loading errorText={errorComponentTable} loading={isLoadingComponentTable} /> 
                        : <DataComponentForm dataComponentTable={dataComponentTable!} id={id} itemsProfile={itemsProfile} dataCOGS={dataCOGS} dataBuying={dataBuying!} />
                      : ""
                    }
                    {activeTab === 3
                      ? isLoadingSelling || errorSelling 
                        ? <Loading errorText={errorSelling} loading={isLoadingSelling} /> 
                        : <DataSellingForm 
                            dataProfile={dataProfile!} 
                            dataSelling={dataSelling} 
                            dataCOGS={dataCOGS!} 
                            dataBuying={dataBuying!} 
                            id={dataProfile?.product_id} 
                            discount={dataProfile?.discount_value} 
                            dataPriceUnit={dataPriceUnit}
                          />
                      : ""
                    }
                    {activeTab === 5
                      ? isLoadingPicture || errorPicture 
                        ? <Loading errorText={errorPicture} loading={isLoadingPicture} /> 
                        : <DataPerformanceForm id={id} /> 
                      : ""
                    }
                    {activeTab === 6
                      ? isLoadingPicture || errorPicture 
                        ? <Loading errorText={errorPicture} loading={isLoadingPicture} /> 
                        : <DataPicturesForm dataPicture={dataPicture} /> 
                      : ""
                    }
                    {activeTab === 7
                      ? isLoadingPicture || errorPicture 
                        ? <Loading errorText={errorPicture} loading={isLoadingPicture} /> 
                        : <DataTransactionForm id={id} /> 
                      : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <section className="mt-2">
          <Card>
            <Card.Body className="flex flex-col gap-5">
              <section className="flex flex-wrap justify-between items-center gap-3">
                <section className="flex flex-wrap gap-3">
                  <CommandButton actiontype="help" />
                  <CommandButton actiontype="print" />
                  <CommandButton actiontype="email" />
                  <CommandButton actiontype="export" />
                </section>
              </section>
            </Card.Body>
          </Card>
        </section>
      </section>
    </RenderProvider>
  )
}
function DataForm(props: {
  data: ProductList
  dataDefault: ProductList
  id: number
  toggle?: () => void
  setActiveTab: (newState: any) => void
  setDisableTab: (newState: any) => void
  itemsProfile?: any
  setItemsProfile: (newState: any) => void
}) {
  const defaultValues = {
    product_code: props.data?.product_code!,
    product_barcode: props.data.product_barcode,
    product_name: props.data.product_name,
    mr_product_group_id: props.data?.mr_product_group_id!,
    mr_product_style_id: props.data.mr_product_style_id,
    main_size_id: props.data.main_size_id,
    mr_product_colour_id: props.data.mr_product_colour_id,
    mr_product_category_id: props.data.mr_product_category_id,
    mr_category_customer_id: props.data.mr_category_customer_id,
    mr_product_material_id: props.data.mr_product_material_id,
    Product_Foto: props.data?.product_foto,
    universal_size: Boolean(props.data.size_type === "1"),
    volume_size: Boolean(props.data.size_type === "2"),
    weight: props.data?.weight,
    weight_unit_id: props.data?.weight_unit_id,
    width: props.data?.width,
    length: props.data?.length,
    height: props.data?.height,
    type: props.data?.type,
  }
  const methods = useForm<ProductList>({ defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData action="DETAIL" onSubmit={() => {}} itemsProfile={props.itemsProfile} />
    </FormProvider>
  )
}
function DataProfileForm(props: { 
  dataProfile: ProfileList 
  itemsProfile?: any
  setItemsProfile: (newState: any) => void
}) {
  const defaultValues = {
    active: props?.dataProfile?.active ? true : false,
    inactive: props?.dataProfile?.inactive ? true : false,
    damage: props?.dataProfile?.damage ? true : false,
    build_item: props?.dataProfile?.build_item ? true : false,
    buy: props?.dataProfile?.buy ? true : false,
    sell: props?.dataProfile?.sell ? true : false,
    inventory: props?.dataProfile?.inventory ? true : false,

    online_description: props?.dataProfile?.online_description ?? "",
    coa_buy: props?.dataProfile?.coa_buy ?? "",
    coa_sell: props?.dataProfile?.coa_sell ?? "",
    coa_asset: props?.dataProfile?.coa_asset ?? "",
    explanation: props?.dataProfile?.explanation ?? "",
    discount_value: props?.dataProfile?.discount_value ?? "",
    discount_start: props?.dataProfile?.discount_start ?? "",
    
    is_apply: props?.dataProfile?.discount_value || props?.dataProfile?.discount_start ? true : false,
    is_online: props?.dataProfile?.online_description ? true : false,
  }
  const methods = useForm<ProfileList>({  defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile action="DETAIL" setItemsProfile={props.setItemsProfile} itemsProfile={props.itemsProfile} />
    </FormProvider>
  )
}
function DataBuyForm(props: { 
  dataBuying: BuyingList 
  dataList: any 
}) {
  const defaultValues = {
    product_id: props?.dataBuying?.product_id ?? "",
    unit_measure_id: props?.dataBuying?.unit_measure_id ?? "",
    quantity_unit_measure: props?.dataBuying?.quantity_unit_measure ?? "",
    price_unit_measure: props?.dataBuying?.price_unit_measure ?? "",
    unit_convention_id: props?.dataBuying?.unit_convention_id ?? "",
    quantity_unit_convention: props?.dataBuying?.quantity_unit_convention ?? "",
    price_unit_convention: props?.dataBuying?.price_unit_convention ?? "",
    datavalue: props?.dataList ?? [],
  }
  const methods = useForm<BuyingList>({ defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormBuying action="DETAIL" onSubmit={() => {}} setBuyingPrice={() => {}} />
    </FormProvider>
  )
}
function DataComponentForm(props: { 
  id?: number
  itemsProfile?: any
  dataCOGS?: any
  dataComponentTable?: ComponentList[]
  dataBuying?: BuyingList
}) {
  type CreateComponentData = Pick<ComponentList, "mr_product_group_id">;
  const defaultValues = { mr_product_group_id: "2" }
  const methods = useForm<CreateComponentData>({ defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormComponent 
        id={props.id} 
        action="DETAIL" 
        onSubmit={() => {}} 
        itemsProfile={props.itemsProfile} 
        dataCOGS={props.dataCOGS} 
        dataComponentTable={props.dataComponentTable}
        dataBuying={props.dataBuying!}
      />
    </FormProvider>
  )
}
function DataSellingForm(props: { 
  id?: number 
  discount?: string
  dataSelling: SellingList[] 
  dataCOGS: ComponentList[]
  dataBuying?: BuyingList
  dataProfile?: ProfileList
  dataPriceUnit?: any
}) {

  const isBuyingTabDisabled = props?.dataProfile?.buy === false ? props?.dataProfile?.buy === false : props?.dataProfile === undefined ? props?.dataProfile === undefined : props?.dataProfile === null
  const isComponentTabDisabled = props?.dataProfile?.build_item === false ? props?.dataProfile?.build_item === false : props?.dataProfile === undefined ? props?.dataProfile === undefined : props?.dataProfile === null

  const defaultValues = { 
    product_id: props?.id,
    product_price_unit_id: props?.dataSelling?.[0]?.product_price_unit_id,
    product_component_cost_id: props?.dataSelling?.[0]?.product_component_cost_id,
    overhead: props?.dataSelling?.[0]?.overhead,
    sales_tax: props?.dataSelling?.[0]?.sales_tax,
    other_changes: props?.dataSelling?.[0]?.other_changes,
    cogm: props?.dataSelling?.[0]?.cogm,
    mr_unit_id: props?.dataSelling?.[0]?.mr_unit_id,
    quantity: props?.dataSelling?.[0]?.quantity,
    tax_id: props?.dataSelling?.[0]?.tax_id,
    price_retail: props?.dataSelling?.[0]?.price_retail,
    wholesales_price: props?.dataSelling?.[0]?.wholesales_price,
    distributor_price: props?.dataSelling?.[0]?.distributor_price,
    factory_price: props?.dataSelling?.[0]?.factory_price,
    online_price: props?.dataSelling?.[0]?.online_price,
    special_arrangement_price: props?.dataSelling?.[0]?.special_arrangement_price,
    
    // price_unit_convention: props?.dataBuying?.price_unit_convention ?? "0",
    // component_at_cost: props?.dataCOGS?.[0]?.component_at_cost ?? "0",
    price_unit_convention: !isBuyingTabDisabled ? props?.dataBuying?.price_unit_convention : "0",
    component_at_cost: !isComponentTabDisabled ? props?.dataBuying?.price_unit_convention : "0",
    discount_amound: props?.discount,
    quantity_unit_sell_convention: props?.dataPriceUnit?.quantity_unit_sell_convention
  }
  const methods = useForm<any>({ defaultValues })

  return (
    <FormProvider {...methods}>
        <FormSelling action="DETAIL" onSubmit={() => {}} setCheckLoading={() => {}} productId={props?.id!} />
    </FormProvider>
  )
}
const DataPicturesForm = (props: { dataPicture?: any }) => {
  return ( <FormPictures  action="DETAIL" dataPicture={props?.dataPicture} refetchPicture={() => {}} /> )
}
const DataPerformanceForm = (props: { id?: number }) => { return ( <FormPerformance id={props.id} disable /> ) }
const DataTransactionForm = (props: { id?: number }) => { return ( <FormTransaction product_id={props?.id} disable /> ) }