import { Fragment } from "react"
import { ActionButton, Modal } from "components"
import { FormSection } from "../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { ActionType } from "../../types"
import { useToggle } from "utils"

export function Detail({ data }: ActionType) {
  const { isActive, toggle } = useToggle(false)
  const methods = useForm({ defaultValues: data })

  return (
    <Fragment>
      <ActionButton.Detail className="join-item" onClick={toggle} />
      {isActive && 
        <Modal isOpen title="DETAIL GROUP TASK" closeModal={toggle}>
          <FormProvider {...methods}>
            <Modal.Body>
              <FormSection action="DETAIL" />
            </Modal.Body>
          </FormProvider>
        </Modal>
      }
    </Fragment>
  )
}