import { Input, Label, Select, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import { ListSubGroupTask } from "../../types"
import { useDropdownGroupTask } from "../../utils";

type FormType = { action?: string }
export function FormSection({ action }: FormType) {
  const { control } = useFormContext<ListSubGroupTask>()
  const { data, isLoading } = useDropdownGroupTask()

  const isDisable = action === "DETAIL" || action === "DELETE"

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="mr_group_sub_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td>
                <Input {...field} 
                  placeholder="Input Group Task Code" 
                  value={field.value ?? ""} 
                  error={fieldState.error?.message} 
                  disabled={isDisable} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="mr_group_task_id"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="GROUP TASK" error={Boolean(fieldState.error)} /></td>
              <td>
                <Select
                  className="my-1"
                  placeholder="Select group task..."
                  isDisabled={isDisable}
                  isLoading={isLoading}
                  error={fieldState.error?.message}
                  options={data}
                  value={data.find(item => item.value?.toString() === field.value?.toString())}
                  onChange={e => field.onChange(e?.value)}
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="mr_group_sub_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="SUB GROUP TASK" error={Boolean(fieldState.error)} /></td>
              <td>
                <Input 
                  {...field} 
                  placeholder="Input Group Task Name" 
                  value={field.value ?? ""} 
                  error={fieldState.error?.message} 
                  disabled={isDisable} 
                />
              </td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="description"
          render={({ field }) => 
            <tr>
              <td><Label text="DESCRIPTION" /></td>
              <td>
                <Textarea 
                  {...field} 
                  placeholder="Input Description" 
                  value={field.value ?? ""}
                  disabled={isDisable} 
                />
              </td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}