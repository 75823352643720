import { useEffect } from "react"
import { Input, Textarea, Select, ColorInput, Modal, HintList, Label } from "components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { DataList } from "../../types"
import { useCountry, useProvince, useRegency, useSubdistrict, useVillage } from "./utils"

export function FormSection(props: { isDetail?: boolean; isUpdate?: boolean; action?: string }) {
  const { control } = useFormContext<DataList>()

  return (
    <Modal.Body
      hintText={(
        <HintList
          title="Creating a Location"
          bottomList={[
            {
              label: "Button: Save Record",
              list: [
                {
                  label: "Purpose",
                  value: "To save the location details after entering all necessary information."
                },
                {
                  label: "Action",
                  value: "Click this button to store the location data and make it available for use in the system."
                },
              ]
            }
          ]}
          list={[
            {
              label: "Location Name",
              list: [
                {
                  label: "Definition",
                  value: "The name or title for the specific location."
                },
                {
                  label: "Purpose",
                  value: "Provides a clear and descriptive name to easily identify and refer to the location."
                },
                {
                  label: "Example",
                  value: "Main Office, Warehouse A, Spa Branch 1."
                }
              ]
            },
            {
              label: "Country",
              list: [
                {
                  label: "Definition",
                  value: "The country where the location is situated."
                },
                {
                  label: "Purpose",
                  value: "Helps in categorizing and managing locations based on their country."
                },
                {
                  label: "Options",
                  value: "Select from a list of countries."
                }
              ]
            },
            {
              label: "Province",
              list: [
                {
                  label: "Definition",
                  value: "The province or state within the country where the location is located."
                },
                {
                  label: "Purpose",
                  value: "Further refines the location details for regional management."
                },
                {
                  label: "Options",
                  value: "Select from a list of provinces or states."
                }
              ]
            },
            {
              label: "Regency",
              list: [
                {
                  label: "Definition",
                  value: "The regency or district within the province where the location is situated."
                },
                {
                  label: "Purpose",
                  value: "Provides a more specific regional classification."
                },
                {
                  label: "Options",
                  value: "Select from a list of regencies or districts."
                }
              ]
            },
            {
              label: "Subdistrict",
              list: [
                {
                  label: "Definition",
                  value: "The subdistrict or smaller administrative unit within the regency."
                },
                {
                  label: "Purpose",
                  value: "Helps in pinpointing the exact area within the regency."
                },
                {
                  label: "Options",
                  value: "Select from a list of subdistricts."
                }
              ]
            },
            {
              label: "Village",
              list: [
                {
                  label: "Definition",
                  value: "The village or neighborhood where the location is found."
                },
                {
                  label: "Purpose",
                  value: "Provides the most specific geographical detail."
                },
                {
                  label: "Options",
                  value: "Select from a list of villages or neighborhoods."
                }
              ]
            },
            {
              label: "Full Address",
              list: [
                {
                  label: "Definition",
                  value: "The complete address of the location, including street number and name."
                },
                {
                  label: "Purpose",
                  value: "Ensures accurate location details for correspondence, logistics, and operations."
                },
                {
                  label: "Example",
                  value: "123 Main Street, Suite 456."
                }
              ]
            },
            {
              label: "Note",
              list: [
                {
                  label: "Definition",
                  value: "Any additional information or remarks related to the location."
                },
                {
                  label: "Purpose",
                  value: "Provides context or special instructions that might be relevant for managing the location."
                },
                {
                  label: "Example",
                  value: "Near the city center or Access through back gate."
                }
              ]
            },
          ]}
        />
      )}
    >
      <table className="table">
        <tbody>
          <Controller
            control={control}
            name="location_name"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label text="NAME" error={Boolean(fieldState.error)} />
                </td>

                <td>
                  <Input
                    {...field}
                    value={field.value ?? ""}
                    error={fieldState.error?.message}
                    disabled={props.isDetail}
                  />
                </td>
              </tr>
            )}
          />

          <Country isDisabled={Boolean(props.isDetail)} />
          <Province isDisabled={Boolean(props.isDetail)} />
          <Regency isDisabled={Boolean(props.isDetail)} />
          <Subdistrict isDisabled={Boolean(props.isDetail)} />
          <Village isDisabled={Boolean(props.isDetail)} />

          <Controller
            control={control}
            name="address"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="ADDRESS"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <Textarea
                    {...field}
                    error={fieldState.error?.message}
                    value={field.value ?? ""}
                    disabled={props.isDetail}
                  />
                </td>
              </tr>
            )}
          />

          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="NOTE"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <Textarea
                    {...field}
                    error={fieldState.error?.message}
                    value={field.value ?? ""}
                    disabled={props.isDetail}
                  />
                </td>
              </tr>
            )}
          />

          <Controller
            control={control}
            name="color"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="COLOR"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <ColorInput
                    {...field}
                    disabled={props.isDetail}
                    error={fieldState.error?.message}
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </table>
    </Modal.Body>
  )
}

function Country(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const { data, isLoading } = useCountry()

  return (
    <Controller
      control={control}
      name="country_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="COUNTRY"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose country"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value?.toString() === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}

function Province(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  const { data, isLoading, refetch } = useProvince()

  useEffect(() => {
    if(country_id) { refetch(country_id!?.toString()) }
    // eslint-disable-next-line
  }, [country_id])

  return (
    <Controller
      control={control}
      name="province_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="PROVINCE"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose province"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value?.toString() === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}

function Regency(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const province_id = useWatch({
    control,
    name: "province_id"
  })
  const { data, isLoading, refetch } = useRegency()

  useEffect(() => {
    if(province_id) { refetch(province_id!?.toString()) }
    // eslint-disable-next-line
  }, [province_id])

  return (
    <Controller
      control={control}
      name="regency_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="REGENCY"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose regency"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value?.toString() === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}

function Subdistrict(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const regency_id = useWatch({
    control,
    name: "regency_id"
  })
  const { data, isLoading, refetch } = useSubdistrict()

  useEffect(() => {
    if(regency_id) { refetch(regency_id!?.toString()) }
    // eslint-disable-next-line
  }, [regency_id])

  return (
    <Controller
      control={control}
      name="subdistrict_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="SUBDISTRICT"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose subdistric"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value?.toString() === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}

function Village(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const subdistrict_id = useWatch({
    control,
    name: "subdistrict_id"
  })
  const { data, isLoading, refetch } = useVillage()

  useEffect(() => {
    if(subdistrict_id) { refetch(subdistrict_id!?.toString()) }
    // eslint-disable-next-line
  }, [subdistrict_id])

  return (
    <Controller
      control={control}
      name="village_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="VILLAGE"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose village"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value?.toString() === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}