import { Button, DateInput, Input, Modal } from "components"
import moment from "moment"
import { Fragment, useState } from "react"
import { toast } from "react-hot-toast"
import { useApi, useToggle } from "utils"
import { TaskActionList } from "../types"
import { ACTION_STATUS } from "../utils/vars"

type ModalDeleteActionLineProps = {
  data: Pick<
    TaskActionList,
    | "task_action_id"
    | "trx_code"
    | "status"
    | "mr_action_task_name"
    | "date_start"
    | "date_end"
    | "description"
    | "assign_to_name"
  >
  modalState: ReturnType<typeof useToggle>
  onSuccess?: () => void
}

export const ModalDeleteActionLine = ({
  data,
  modalState,
  onSuccess
}: ModalDeleteActionLineProps) => {
  const [loadingData, setLoadingData] = useState(false)
  const api = useApi()

  const handleDeleteData = async () => {
    setLoadingData(true)
    await toast
      .promise(api.delete(`task_action/${data?.task_action_id}`), {
        loading: "Loading...",
        success: (res) => res?.data?.message,
        error: (err) => err?.response?.data?.message
      })
      .then(() => {
        modalState.setActive(false)
        onSuccess?.()
      })
      .finally(() => {
        setLoadingData(false)
      })
  }

  return (
    <Modal
      isOpen={modalState.isActive}
      title="DELETE ACTION TASK"
      size="xl"
      closeModal={modalState.toggle}>
      <Modal.Body>
        <Fragment>
          <small className="text-lg font-medium text-red-400">
            Are you sure want to delete this data?
          </small>

          <Input disabled label="TASK ID" defaultValue={data.trx_code} />

          <Input
            disabled
            label="ACTION"
            defaultValue={data.mr_action_task_name}
          />

          <Input
            disabled
            label="STATUS"
            defaultValue={ACTION_STATUS[data.status]}
          />

          <div className="flex justify-start items-center gap-x-3">
            <DateInput
              disabled
              label="DATE START"
              selected={moment(data.date_start).toDate()}
              onChange={() => null}
            />

            <DateInput
              disabled
              label="DATE END"
              selected={moment(data.date_end).toDate()}
              onChange={() => null}
            />
          </div>

          <Input disabled label="DESCRIPTION" defaultValue={data.description} />

          <Input
            disabled
            label="ASSIGN TO"
            defaultValue={data.assign_to_name}
          />
        </Fragment>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="error"
          disabled={loadingData}
          loading={loadingData ? "true" : undefined}
          onClick={handleDeleteData}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
