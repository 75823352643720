import { Controller, useFormContext } from "react-hook-form"
import { DataList } from "../../types"
import { Input, Label, Textarea } from "components"
import { menuTitle } from "../ChildItem/components/Item/components"

export function FormSection(props: {
    isDetail?: boolean
    isUpdate?: boolean
    action?: string
}) {
    const { control } = useFormContext<DataList>()

    return (
        <table className="table">
            <tbody>
                <Controller
                    control={control}
                    name="mr_comparation_code"
                    render={({ field, fieldState }) => 
                        <tr>
                            <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
                            <td>
                                <Input 
                                    {...field} 
                                    placeholder="Input Code"
                                    value={field.value ?? ""} 
                                    disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                                    error={fieldState.error?.message}    
                                />
                            </td>
                        </tr>
                    }
                />

                <Controller
                    control={control}
                    name="mr_comparation_name"
                    render={({ field, fieldState }) => 
                        <tr>
                            <td><Label text={menuTitle.title} error={Boolean(fieldState.error)} /></td>
                            <td>
                                <Input 
                                    {...field} 
                                    placeholder={`Input ${menuTitle.title}`}
                                    value={field.value ?? ""} 
                                    disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                                    error={fieldState.error?.message}    
                                />
                            </td>
                        </tr>
                    }
                />

                <Controller
                    control={control}
                    name="description"
                    render={({ field }) => 
                        <tr>
                            <td><Label text="DESCRIPTION" /></td>
                            <td><Textarea {...field} placeholder="Input Description" value={field.value ?? ""} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
                        </tr>
                    }
                />
            </tbody>
        </table>
    )
}