import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import type { ListBank } from "../../types"
import { useApi } from "../../../../../../utils"

interface FilteredParams {
  sort?: string
}
export function useList({sort}: FilteredParams) {
  const api = useApi()
  const [data, setData] = useState<ListBank[]>([])
  const [isLoading, setLoading] = useState(true)

  const params: { [key: string]: number | string | undefined } = {};
  if (sort) params.sort = sort;

  const fetchUrl = () => api.get("/groupcoa/", { params })

  const fetchData = () => {
    setLoading(true)

    fetchUrl().then((res: SuccessFetch<{
      payload:  {
        group_coa_code: string
        group_coa_name: string
        group_coa_type: number,
        description: string,
      }[]
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [sort])

  return { data, isLoading }
}