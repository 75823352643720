import { CommandButton, FooterMenu, Modal, Table } from "components";
import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { convertNumber, useToggle } from "utils";
import { FormType } from "../utils/vars";
import moment from "moment";
import { useAccountKredit, useProduct } from "../utils";

export function FormAction(props: PropsWithChildren<{
    onSubmit?: () => void,
    isEdit?: boolean
}>) {
    return (
        <Fragment>
            <section className="container my-2">
                {props.children}
            </section>
            <section className="mt-2">
                {props.isEdit && (<FooterMenu
                    save={{
                        permission: "AT021",
                        actiontype: "save",
                        type: "button",
                        color: "primary",
                        onClick: props.onSubmit
                    }}
                    customElement={{
                        journal: <JournalModal />,
                        attachment: <CommandButton actiontype="attachment" />
                    }}
                />)}
                
            </section>
        </Fragment>
    )
}


function JournalModal() {
    const { isActive, toggle } = useToggle(false)
    return (
        <Fragment>
            <CommandButton actiontype="journal" onClick={toggle} />

            {isActive && (
                <Modal isOpen title="JOURNAL" closeModal={toggle} size="full">
                    <Modal.Body>
                        <JournalTable />
                    </Modal.Body>
                </Modal>
            )}
        </Fragment>
    )
}


function JournalTable() {
    const { dataProduct } = useProduct()
    const { dataAccount } = useAccountKredit()
    const { watch } = useFormContext<FormType>()
    const [account, setAccount] = useState('')

    const date = watch('transaction_date')
    const price = watch('av_price_unit')
    const soh = watch('soh')
    const productId = watch('product_id')

    const sumValue = (data: any[], position: 1 | 2) => {
        return data.reduce((acc, item) => acc + position === 1 ? item.debet : item.kredit, 0)
    }


    useEffect(() => {
        const SelectedProduct = dataProduct.find(value => value.prouct_id.toString() === productId.toString())
        if (SelectedProduct) {
            setAccount(`${SelectedProduct.coa_code}  ${SelectedProduct.coa_name}`)
        }
        /* eslint-disable */
    }, [productId, dataProduct])

    const dataJournal = [
        {
            dateOpening: date,
            idm: '-',
            account: account,
            debet: Number(price) * Number(soh ?? '0'),
            kredit: '0'
        },
        {
            dateOpening: date,
            idm: '-',
            account: `${dataAccount?.coa_code}  ${dataAccount?.coa_name}`,
            debet: '0',
            kredit: Number(price) * Number(soh ?? '0')
        },
    ]

    const total_amount = {
        debet: sumValue(dataJournal, 1),
        kredit: sumValue(dataJournal, 2)
    }

    return (
        <Fragment>
            <Table
                data={dataJournal}
                columns={[
                    {
                        title: "DATE",
                        thProps: { className: "w-[10px]" },
                        field: () => ""
                    },
                    {
                        title: "IDM",
                        field: () => ""
                    },
                    {
                        title: "ACCOUNT",
                        field: () => ""
                    },
                    {
                        title: "DEBET",
                        field: () => ""
                    },
                    {
                        title: "CREDIT",
                        field: () => ""
                    }
                ]}
            >
                <tbody>
                    {dataJournal?.map((data, key) => (
                        <tr key={key}>
                            <td>{moment(data.dateOpening).format("DD/MM/YYYY")}</td>
                            <td>{data.idm}</td>
                            <td>{data.account}</td>
                            <td className="text-right">{convertNumber(Number(data.debet)).intoCurrency}</td>
                            <td className="text-right">{convertNumber(Number(data.kredit)).intoCurrency}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr className="text-xl font-bold">
                        <th colSpan={3}></th>
                        <th className="text-right">{convertNumber(total_amount.debet).intoCurrency}</th>
                        <th className="text-right">{convertNumber(total_amount.kredit).intoCurrency}</th>
                    </tr>
                </tfoot>
            </Table>
        </Fragment>
    )
}