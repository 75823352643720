import { Fragment } from "react"
import type { ListBank } from "../../../../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi, useToggle } from "utils"

export function IsActive(props: {
    id: ListBank
    refetch?: () => void
}) {
    // Hooks
    const api = useApi()
    const { isActive, toggle } = useToggle(props.id.is_deleted ?? false)

    const onSubmit = (value: ListBank) => {
        return new Promise<void>((resolve) => {
            toggle()
            toast.promise(
                api.put("/bank/changestatus",
                    {
                        bank_id: value.bank_id,
                        statusdel: !isActive
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {
            }).catch(() => {
                toggle()
            }).finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle
                label="Soft Delete"
                isChecked={isActive} 
                onClick={() => {
                    onSubmit(props.id);
                }} />
        </Fragment>
    )
}