// React
import { useState } from "react"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { SuccessFetch } from "types"
import type { ProductType } from "../../types"

// Utils
import { useApi } from "utils"

export function useProduct() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<ProductType[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const refetch = (vendor_id: string) => {
    setLoading(true)

    api.get("/product/byvendor", { params: { vendor_id } }).then((res: SuccessFetch<{
      payload: {
        buying_price: number
        unit_measure_name: string
        product_barcode: string
        product_id: number
        product_name: string
        unit_measure_id: number
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          buying_price: item.buying_price,
          label: `${item.product_barcode} - ${item.product_name}`,
          mr_unit_name: item.unit_measure_name,
          product_barcode: item.product_barcode,
          product_name: item.product_name,
          value: item.product_id.toString(),
          weight_unit_id: item.unit_measure_id.toString()
        }
      }))
    }).catch(() => {
      toast.error("Product is not match with data type")
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}