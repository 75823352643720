// React
import React from 'react'

// Styles
import './index.css'

// Components
import { Layout } from './components'

// Configs
import { menu, setupMenu } from './configs'

// Contexts
import { AuthProvider, ThemeProvider } from 'contexts'

// Pages
import Subscribe from 'pages/Subscribe'
import Login from './pages/Login'

// Routes
import { accountsRoutes } from 'routes/accountsRoutes'
import { cardsRoutes } from 'routes/cardsRoutes'
import { financeRoutes } from 'routes/financeRoutes'
import { inventoryRoutes } from 'routes/inventoryRoutes'
import { purchaseRoutes } from 'routes/purchaseRoutes'
import { salesRoutes } from 'routes/salesRoutes'
import { mainRoute } from './routes/mainRoute'
import { setupRoute } from './routes/setupRoute'
import { tasksRoutes } from 'routes/tasksRoutes'

// Third-Party Libraries
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

// Types
import type { RouteType } from 'types'

// Utils
import { getBasenamePath, getValidationSubdomain, routeMapper } from 'utils'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

// Vars
const routeModuleList: { [key: string]: RouteType[] } = {
  ANALYTICS: [],
  ACCOUNTS: accountsRoutes,
  FINANCE: financeRoutes,
  SALES: salesRoutes,
  PURCHASES: purchaseRoutes,
  INVENTORY: inventoryRoutes,
  CARDS: cardsRoutes,
  TASKS: tasksRoutes
}

const { basename, companyName } = getBasenamePath()

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    loader: () => getValidationSubdomain({ subdomain: companyName })
      .then(res => res?.data)
      .catch(() => null)
  },
  {
    path: "/subscribe",
    element: <Subscribe />
  },
  {
    path: "/setup",
    children: routeMapper(setupRoute),
    element: (
      <Layout
        outletClass='py-5'
        module_menu={setupMenu}
      />
    )
  },
  {
    path: "/",
    element: (
      <Layout
        module_menu={menu}
        routeList={routeModuleList}
      />
    ),
    children: mainRoute
  },
], { basename })


root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <RouterProvider router={router} />
        <Toaster />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
