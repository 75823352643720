// Components
import { Card, CommandButton, FooterMenu, HeaderMenu } from "components"
import { Acc, AddReminder, Date, Deliver, Freight, Memo, PromisedDate, RecurringButton, Supplier, SupplierId, TableSection, TaxInclusive, TradingTerm } from "../components"
import { Code, Tab, Table } from "./components"

// Contexts
import { DataJournalContextProvider } from "contexts"
import { ReminderContext } from "../contexts"
import { PurchaseOrderContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"

// Utils
import { useApi, freightAlert } from "utils"
import { type FormType, validation_schema, getFinalValue } from "./utils"

export default function PurchaseOrder() {
  // Hooks
  const api = useApi()

  // Form
  const default_values: FormType = {
    _vendor_name: "",
    approval_status: 0,
    address: "",
    freight: 0,
    location_id: "",
    memo: "Purchase order transaction",
    payment_term: "",
    promize_date: moment().format("YYYY-MM-DD"),
    referensi: "",
    tax_inclusive: true,
    transaction_date: moment().format("YYYY-MM-DD"),
    vendor_id: "",
    podetail: [],
    reminder: null
  }
  const onSubmit = (value: FormType) => {
    // Vars
    const final_value = getFinalValue(value)

    return new Promise<void>((resolve) => {
      // Functions
      const submitForm = (): void => {
        toast.promise(
          api.post("/purchaseorder/add", final_value),
          {
            loading: "Loading...",
            success: (res) => res.data.message,
            error: (err) => err.response.data.detail?.message ?? err.response.data.message
          }
        ).then(() => {
          methods.reset()

          // Scroll to Top
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }).catch(() => { }).finally(resolve)
      }

      if (!value.freight) {
        freightAlert("Purchase Order").then(() => {
          submitForm()
        }).catch(() => {
          return resolve()
        })
      } else {
        submitForm()
      }
    })
  }
  const methods = useForm<FormType>({
    defaultValues: default_values,
    resolver: yupResolver(validation_schema)
  })

  return (
    <PurchaseOrderContext.Provider value={{ disabled: false }}>
      <FormProvider {...methods}>
        <section className="container my-5 flex flex-col gap-2">
          <HeaderMenu title="DATA ENTRY | PURCHASE ORDER">
            <Code />
          </HeaderMenu>

          <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
            <Supplier
              name="vendor_id"
              trading_term_name="payment_term"
              vendor_name="_vendor_name"
              detail="podetail"
            />

            <TradingTerm name="payment_term" />
            <TaxInclusive name="tax_inclusive" />
          </section>

          <Card>
            <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
              <section className="flex flex-col gap-3">
                <Deliver
                  deliver_name="location_id"
                  detail_name="address"
                />

                <Memo name="memo" />
              </section>

              <section className="flex flex-col gap-3">
                <Date name="transaction_date" />
                <SupplierId name="referensi" />
                <PromisedDate name="promize_date" />

                <ReminderContext.Provider
                  value={{
                    disabled: false,
                    transaction_from: "PURCHASE ORDER",
                    name: {
                      memo: "memo",
                      promise_date: "promize_date",
                      reminder: "reminder",
                      referensi: "referensi",
                      transaction_date: "transaction_date",
                      vendor_name: "_vendor_name",
                      reminder_child: {
                        memo: "reminder.memo",
                        promise_date: "reminder.promise_date",
                        transaction_date: "reminder.transaction_date"
                      }
                    },
                    onSuccess: (value) => {
                      methods.setValue("reminder.memo", value.memo)
                      methods.setValue("reminder.promise_date", value.promise_date)
                      methods.setValue("reminder.transaction_date", value.transaction_date)
                    }
                  }}
                >
                  <AddReminder />
                </ReminderContext.Provider>
              </section>
            </Card.Body>
          </Card>

          <DataJournalContextProvider>
            <Table />

            <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
              <section className="flex flex-col gap-3">
                <TableSection>
                  <TableSection.Row
                    label="PAYMENT STATUS"
                    value="-"
                  />

                  <TableSection.Row
                    label="APPROVE STATUS"
                    value="-"
                  />

                  <TableSection.Row
                    label="APPROVED BY"
                    value="-"
                  />
                </TableSection>

                <Freight name="freight" />
                <RecurringButton />
              </section>

              <Acc
                name={{
                  detail: "podetail",
                  freight: "freight"
                }}
              />
            </section>
          </DataJournalContextProvider>

          <Tab />

          <FooterMenu
            save={{
              permission: "PT021",
              actiontype: "save",
              type: "button",
              color: "primary",
              loading: methods.formState.isSubmitting ? "true" : undefined,
              onClick: methods.handleSubmit(onSubmit)
            }}
            
            customElement={{ 
              attachment: <CommandButton actiontype="attachment" />,
              journal: <CommandButton actiontype="journal" />,
             }}
          />

        </section>
      </FormProvider>
    </PurchaseOrderContext.Provider>
  )
}