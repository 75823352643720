import moment from "moment"

const format = (date: moment.Moment) => date.format("YYYY-MM-DD")

export const prevYear = () => {
  const fromDate = moment().subtract(1, "year").startOf("year")
  const toDate = moment().subtract(1, "year").endOf("year")

  return { fromDate: format(fromDate), toDate: format(toDate) }
}

export const thisYear = () => {
  const fromDate = moment().startOf("year")
  const toDate = moment().endOf("year")

  return { fromDate: format(fromDate), toDate: format(toDate) }
}

export const prevMonth = () => {
  const fromDate = moment().clone().subtract(1, "months").startOf("month")
  const toDate = moment().clone().subtract(1, "months").endOf("month")

  return { fromDate: format(fromDate), toDate: format(toDate) }
}

export const thisMonth = () => {
  const fromDate = moment().startOf("month")
  const toDate = moment().endOf("month")

  return { fromDate: format(fromDate), toDate: format(toDate) }
}

export const thisWeek = () => {
  const fromDate = moment().startOf("week")
  const toDate = moment().endOf("week")

  return { fromDate: format(fromDate), toDate: format(toDate) }
}

export const thisQuarter = () => {
  const fromDate = moment().startOf("quarter")
  const toDate = moment().endOf("quarter")

  return { fromDate: format(fromDate), toDate: format(toDate) }
}

export const prevQuarter = () => {
  const fromDate = moment().clone().subtract(1, "quarter").startOf("quarter")
  const toDate = moment().clone().subtract(1, "quarter").endOf("quarter")

  return { fromDate: format(fromDate), toDate: format(toDate) }
}

export const today = () => format(moment())

export const nextWeek = () => moment().add(7, "days").format("YYYY-MM-DD")

export const nextBiWeek = () => moment().add(14, "days").format("YYYY-MM-DD")

export const nextThreeWeek = () => moment().add(21, "days").format("YYYY-MM-DD")

export const nextMonth = () => moment().add(1, "month").format("YYYY-MM-DD")

export const endOfYear = () => format(moment().clone().endOf("year"))
