import moment from "moment"
import { Fragment } from "react"
import { TbHistory, TbPlus, TbTrash } from "react-icons/tb"
import { useToggle } from "utils"
import { TaskActionList, TaskList } from "../types"
import { useTaskActionList } from "../utils/hooks"
import { ACTION_STATUS } from "../utils/vars"
import { ModalActionLine } from "./ModalActionLine"
import { ModalDeleteActionLine } from "./ModalDeleteActionLine"

type CardTaskProps = {
  task: Partial<TaskList>
  actionTask: TaskActionList
  taskAction: ReturnType<typeof useTaskActionList>
}

export const CardTask = ({ task, actionTask, taskAction }: CardTaskProps) => {
  const modalState = useToggle(false)
  const modalDelete = useToggle(false)
  const bgColor =
    actionTask.status === 1
      ? "bg-notstarted"
      : actionTask.status === 2
      ? "bg-inprogress"
      : actionTask.status === 3
      ? "bg-completed"
      : "bg-cancelled"

  return (
    <Fragment>
      <div className={`${bgColor} p-3 text-black`}>
        <div className="w-full flex gap-2 justify-end items-center ">
          <div className="btn btn-sm btn-ghost p-1" onClick={modalState.toggle}>
            <TbPlus size={16} />
          </div>

          <div className="btn btn-sm btn-ghost p-1">
            <TbHistory size={16} />
          </div>

          <div
            className="btn btn-sm btn-ghost p-1"
            onClick={modalDelete.toggle}>
            <TbTrash size={16} />
          </div>
        </div>

        <table>
          <tbody>
            <tr>
              <td>Action ID#</td>
              <td>: {actionTask.trx_code}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>: {ACTION_STATUS[actionTask.status ?? 1]}</td>
            </tr>
            <tr>
              <td>Action</td>
              <td>: {actionTask.mr_action_task_name ?? ""}</td>
            </tr>
            <tr>
              <td>Assign To</td>
              <td>: {actionTask.assign_to_name}</td>
            </tr>
            <tr>
              <td>Action Date</td>
              <td>
                :{" "}
                <span className="text-red-500">
                  {actionTask?.date_start
                    ? moment(actionTask?.date_start).format("ddd, DD MMM YYYY")
                    : ""}
                </span>{" "}
                |{" "}
                <span className="text-blue-500">
                  {actionTask?.date_end
                    ? moment(actionTask?.date_end).format("ddd, DD MMM YYYY")
                    : ""}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="font-bold mt-5">Description: </div>
        <p>{actionTask.description}</p>

        <div className="w-full flex flex-col gap-1 justify-end items-end mt-3 text-sm">
          <span>
            C:{" "}
            {actionTask.create_at
              ? moment(actionTask.create_at).format("DD/MM/YYYY")
              : "-"}{" "}
            {actionTask.create_by_name ? `| ${actionTask.create_by_name}` : ""}
          </span>
          <span>
            M:{" "}
            {actionTask.update_at
              ? moment(actionTask.update_at).format("DD/MM/YYYY")
              : "-"}{" "}
            {actionTask.update_by_name ? `| ${actionTask.update_by_name}` : ""}{" "}
          </span>
        </div>
      </div>

      <ModalActionLine
        task={task}
        data={actionTask}
        modalState={modalState}
        taskAction={taskAction}
      />

      <ModalDeleteActionLine
        data={actionTask}
        modalState={modalDelete}
        onSuccess={() => {
          modalState.setActive(false)
          taskAction.refetch()
        }}
      />
    </Fragment>
  )
}
