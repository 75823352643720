import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"

export function Update(props: { id: DataList, refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
    </Fragment>
  )
}


function ModalSection(props: {
  id: DataList
  toggle: () => void
  refetch: () => void
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title="EDIT DIVISI" size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: DataList
  id: DataList
  toggle: () => void
  refetch: () => void
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  const validationSchema = yup.object().shape({
    divisi_code: yup.string().label("Divisi code").required().max(3, 'Maximum code is 3 characters'),
    divisi_name: yup.string().label("Divisi name").required().max(100, "Must be 100 characters or less"),
  })
  const onSubmit = ( value: DataList ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/divisi/edit?divisi_id=${props.id.divisi_id}`, 
        { 
          divisi_code: value.divisi_code, 
          divisi_name: value.divisi_name, 
          description: value.description, 
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: props.id,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <FormSection isUpdate />
        </Modal.Body>

        <Modal.Footer className="text-end">
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}