import { Button, Card, DateInput, FooterMenu, Select } from "components";
import { FormType, RenderProvider, validationSchema } from "./utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useApi } from "utils";
import moment from "moment";
import { useList } from "./utils";
import { useEffect } from "react";

export default function SettingCompany() {

    const api = useApi()
    const {data, isLoading} = useList()
    const methods = useForm({
        resolver: yupResolver(validationSchema),
    })

    useEffect(() => {
        if (data) {
          methods.setValue("methode_avg_price", Number(data.methode_avg_price))
        }
     // eslint-disable-next-line
      }, [data])

    const dropdownAverage = [
        {value: '1', label: 'Average Price'},
        {value: '2', label: 'Last Price'},
        // {value: '3', label: 'Standar Price'},
    ]

    const onSubmit = (value: FormType) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post(`/settingcompany/`, value), {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                     error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {}).catch(() => {}).finally(resolve)
        })
    }

    return (
        <RenderProvider>
            <section className="container pt-5 gap-5 flex flex-col">
                <Card>
                    <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
                        <div className="bg-black p-3 text-white rounded-lg">COMPANY SETUP</div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <div className="lg:flex gap-2 items-center mb-2">
                            <div className="lg:w-1/2">
                                <Controller
                                    control={methods.control}
                                    name="finance_year"
                                    render={({ field, fieldState }) => (
                                        <DateInput
                                            label="FiNANCE YEAR"
                                            error={fieldState.error?.message}
                                            className="w-full mr-2"
                                            ref={field.ref}
                                            onChange={value => field.onChange(moment(value).format("YYYY"))}
                                            selected={field.value ? moment(field.value, "YYYY").toDate() : null}
                                            dateFormat="yyyy"
                                            showYearPicker
                                             placeholderText="Input finance year"
                                        />
                                    )}
                                />
                            </div>
                            <div className="lg:w-1/2">
                                <Controller
                                    control={methods.control}
                                    name="methode_avg_price"
                                    render={({ field, fieldState }) => (
                                        <Select
                                            label="METODE AVERAGE PRICE"
                                            placeholder="Select metode average price"
                                            error={fieldState.error?.message}
                                            className="w-full"
                                            isLoading={isLoading}
                                            options={dropdownAverage}
                                            value={dropdownAverage.find(item => item.value === field.value?.toString())}
                                            onChange={e => field.onChange(e?.value)}
                                        />
                                    )}
                                />
                            </div>
                            <div className="mt-8 flex justify-end">
                                <Button color="primary" onClick={methods.handleSubmit(onSubmit)} loading={methods.formState.isSubmitting ? "true" : undefined}>SAVE</Button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <FooterMenu/>
            </section>
        </RenderProvider>
    )
}