// React
import { useContext } from "react"

// Components
import { ActionButton, AddProject, BaseTable, Loading, TaxCode } from "components"
import { DataJournal } from "./components"

// Contexts
import { DataJournalContext, DataJournalContextProvider } from "pages/Finance/Transaction/contexts"

// Form
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form"

// Types
import type { FormType } from "pages/Finance/Transaction/utils"

// Utils
import { convertNumber, useToggle } from "utils"

export function AccountTable(): JSX.Element {
  return (
    <DataJournalContextProvider>
      <div className="overflow-x-auto">
        <BaseTable>
          <thead>
            <tr>
              <th>-</th>
              <th>ACC EXPENSES</th>
              <th>MEMO</th>
              <th>AMOUNT</th>

              <th className="whitespace-nowrap">
                PROJECT ID <AddDataProject />
              </th>

              <th>REF NO</th>
              <AddDataTaxCode />
              <th>ACTION</th>
            </tr>
          </thead>

          <Body />
        </BaseTable>
      </div>
    </DataJournalContextProvider>
  )
}

function AddDataProject(): JSX.Element {
  // Hooks
  const { project } = useContext(DataJournalContext)

  return <AddProject onSuccess={project.refetch} />
}

function AddDataTaxCode(): JSX.Element {
  // Hooks
  const { tax_code } = useContext(DataJournalContext)

  return <TaxCode onSuccess={tax_code.refetch} />
}

function Body(): JSX.Element {
  // Hooks
  const { project, tax_code } = useContext(DataJournalContext)

  // Form
  const { control } = useFormContext<FormType>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "datajournal"
  })

  if (project.isLoading || tax_code.isLoading) {
    return <Loading loading />
  } else {
    return (
      <tbody>
        {fields.map((field, key) => <EditJournal key={field.id} index={key} onDeleteItem={() => remove(key)} />)}
        <DataJournal key={fields.length} method="add" onAddJournal={append} />
      </tbody>
    )
  }
}

function EditJournal(props: {
  index: number
  onDeleteItem: () => void
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  // Form
  const { control } = useFormContext<FormType>()
  const tax_inclusive = useWatch({
    control,
    name: "tax_inclusive"
  })

  return (
    <Controller
      control={control}
      name={`datajournal.${props.index}` as "datajournal.0"}
      render={({ field }) => {
        if (isActive) {
          return (
            <DataJournal
              defaultValue={field.value}
              method="edit"
              onCancelEditing={toggle}
              onAddJournal={(value) => {
                field.onChange(value)
                toggle()
              }}
            />
          )
        } else {
          return (
            <tr>
              <td className="text-center">{props.index + 1}</td>
              <td>{field.value.coa_name}</td>
              <td>{field.value.memo}</td>
              <td className="text-right">
                {convertNumber(field.value.amount - (tax_inclusive ? field.value.amount * field.value.rate / 100  : 0)).intoCurrency}
              </td>
              <td>{field.value.project_name}</td>
              <td>{field.value.referensi}</td>
              <td>{field.value.tax_name}</td>
              <td>
                <section className="flex justify-center">
                  <ActionButton.Update onClick={toggle} />
                  <ActionButton.Delete onClick={props.onDeleteItem} />
                </section>
              </td>
            </tr>
          )
        }
      }}
    />
  )
}