// Components
import { Toggle } from "components/actions/Toggle"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { DataList } from "../../../../../types"

// Utils
import { useApi, useToggle } from "utils"

export function IsActive(props: {
	id: DataList
	refetch?: () => void
}) {
	// Hooks
	const api = useApi()
	const { isActive, toggle } = useToggle(props.id.is_deleted ?? false)

	// Functions
	const onSubmit = (value: DataList) => {
		toggle()

		return new Promise<void>((resolve) => {
			toast.promise(
				api.put(
					"/typetax/changestatus",
					{
						type_tax_id: value.type_tax_id,
						type_taxdel: !isActive
					}
				),
				{
					loading: "Loading...",
					success: (res) => res.data.message,
					error: (err) => err.response.data.message ?? err.response.message
				}
			).catch(() => {
				toggle()
			}).finally(resolve)
		})
	}

	return (
		<Toggle
			label="Soft Delete" 
			isChecked={isActive} 
			onClick={() => onSubmit(props.id)}
		/>
	)
}