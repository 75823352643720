import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"

export function CreateModal(props: {
  divisi_code?: string
  divisi_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("divisi_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "divisi_id"> 

  const defaultValues:CreateData= {
    divisi_code: "",
    divisi_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    divisi_code: yup.string().label("Divisi code").required().max(3, 'Maximum code is 3 characters'),
    divisi_name: yup.string().label("Divisi name").required().max(100, "Must be 100 characters or less"),
    description: yup.string().label("Description").optional(),
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/divisi/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE DIVISI" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`Defining data attributes for "Company Department Division" within an ERP system is crucial for further detailing and subdividing larger departmental areas into more focused groups. This division helps in specialized management of tasks, more targeted resource allocation, and enhanced reporting capabilities. Here’s how to comprehensively explain the data attributes for "Company Department Division":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each division within a department."
                      },
                      {
                        label: "Purpose",
                        value: "he code facilitates quick and consistent identification and management of different divisions within departments in the ERP system. It enhances data management by simplifying data entry, improving searchability, and ensuring uniformity across the organizational structure."
                      },
                      {
                        label: "Example",
                        value: `Codes such as "DIV01" for "Recruitment," "DIV02" for "Employee Relations," or "DIV03" for "Payroll" help distinctly manage and identify divisions within the broader "Human Resources" department.`
                      }
                    ]
                  },
                  {
                    label: "Division",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the division within a specific department."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the division’s role, helping to clarify the specific focus or specialization within a larger department. It is essential for organizing internal processes, delegating responsibilities, and facilitating inter-departmental coordination."
                      },
                      {
                        label: "Example",
                        value: `Within the "Finance" department: 
                                "Accounts Payable" — Manages all outgoing payments. 
                                "Accounts Receivable" — Handles incoming payments. 
                                Within the "Sales" department: 
                                "Domestic Sales" — Focuses on sales within the home country. 
                                "International Sales" — Manages sales across international markets.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text providing additional details about the role and function of the division."
                      },
                      {
                        label: "Purpose",
                        value: "The description provides deeper insights into the operations, responsibilities, and goals of the division. It assists in understanding how the division fits into the overall objectives of the department and the company, ensuring all employees are aligned and informed about their roles."
                      },
                      {
                        label: "Example",
                        value: `For "Accounts Payable," the description might be: "Responsible for processing and managing all supplier payments and other financial liabilities. Ensures timely and accurate payment of invoices, maintaining good vendor relations and financial health." 
                                For "Accounts Receivable," it could read: "Focused on managing incoming funds, invoicing, and ensuring timely collection of payments from customers. Critical for maintaining cash flow and revenue accuracy." 
                                For "Domestic Sales," it might state: "Handles all sales activities within the domestic market, striving to increase market share and customer satisfaction through effective sales strategies and customer service." 
                                For "International Sales," it might note: "Expands the company's global footprint by developing and implementing strategies for selling products in international markets. Deals with complexities of cross-border commerce including tariffs, customs, and different market dynamics."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are essential for managing the company's departmental divisions within the ERP system. They enable detailed organization, clear responsibility mapping, and effective management of specialized tasks, contributing to the overall efficiency and success of the company."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}