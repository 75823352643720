import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils"


interface DataType {
    methode_avg_price: string
}

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataType | null>(null)
  const [isLoading, setLoading] = useState(true)

  const fetchUrl = () => api.get("/settingcompany/")

  const fetchData = () => {
    setLoading(true)

    fetchUrl().then((res: SuccessFetch<{
      payload: DataType
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    fetchData()

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}