import { Fragment, useEffect } from "react"
import { BaseTable, Card, FooterMenu, Loading, RegisterLayout } from "../../../components"
import { Create, Item } from "./components"
import { DataProvider, useCoaGroup, useData, useFilter } from "./utils"
import { usePermission } from "utils"

interface SelectOptionType {
  label: string
  value: string
  permission?: string
}

export default function Register() {
  const { isLoading } = useCoaGroup()
  const dataTab = [
    { label: "Show All", value: "", permission: "AR01" },
    { label: "ASSETS", value: "01", permission: "AR02" },
    { label: "LIABILITY", value: "02", permission: "AR03" },
    { label: "EQUITY", value: "03", permission: "AR04" },
    { label: "INCOME", value: "04", permission: "AR05" },
    { label: "COST OF GOOD SOLD", value: "05", permission: "AR06" },
    { label: "EXPENSES", value: "06", permission: "AR07" },
    { label: "OTHER INCOME", value: "07", permission: "AR08" },
    { label: "OTHER EXPENSES", value: "08", permission: "AR09" }
  ]

  if (isLoading) {
    return (
      <section className="min-h-[500px]">
        <Loading loading />
      </section>
    )
  } else {
    return <ViewSection dataFilter={dataTab} />
  }
}

export function ViewSection(props: { dataFilter: SelectOptionType[] }) {
  const { value, setValue } = useFilter(props.dataFilter[0].value)
  const { data, isLoading, refetch } = useData()
  
  const permissionData = props?.dataFilter.find(element => element.value === value);
  const permission = usePermission([permissionData?.permission?.toString()!]);

  const items = data.filter(item => {
    const isParent: boolean = Boolean(item.parent_id === -1)

    if (value !== "") {
      return isParent && item.group_coa_code === value
    }

    return isParent
  })

  useEffect(() => {
    refetch()

    // eslint-disable-next-line
  }, [value])

  return (
    <Fragment>
      <section className="my-5 mx-5">
        {isLoading ? (
          <Loading loading />
        ) : (
          <DataProvider data={data} refetch={refetch}>
            <RegisterLayout.Header title="CHART OF ACCOUNTS REGISTER" />

            <RegisterLayout.Filter
              list={props.dataFilter}
              value={value}
              onChangeFilter={setValue}
            />

              <section className="mt-2">
                <Card>
                  <Card.Body className="flex-row justify-between items-center">
                    <Card.Title>CHART OF ACCOUNT</Card.Title>
                    {Boolean(value === "" || data.length === 0) && <Create onSuccess={refetch} />}
                  </Card.Body>

                  <Card.Body>
                    <div className="flex flex-col min-h-[calc(100vh-520px)]">
                      <BaseTable className="border-collapse">
                        <thead>
                          <tr>
                            <th>NO</th>
                            <th>CONTENT</th>
                            <th>HIERARCHY</th>
                            <th>CHQ</th>
                            <th>ACCOUNT</th>
                            <th>NAME</th>
                            <th>LINKED</th>
                            <th>LEVEL</th>
                            <th>ACC TYPE</th>
                            <th>BALANCE</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        
                        <tbody>
                          {items.length === 0 ? (
                            <tr>
                              <td colSpan={10} className="text-center">
                                <Loading errorText="No data available" />
                              </td>
                            </tr>
                          ) : items.map((item, key) => (
                            <Item permission={permission} key={key} index={key} item={item} onSuccess={refetch} />
                          ))}
                        </tbody>
                      </BaseTable>
                    </div>
                  </Card.Body>
                </Card>
              </section>

              <section className="mt-2">
                <FooterMenu />
              </section>
          </DataProvider>
        )}
      </section>
    </Fragment>
  )
}