import { Button, HeaderMenu, Select } from "components"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { TableTaskList } from "./components/Tables/TaskList"
import { useTaskList } from "./utils/hooks"
import { TASK_STATUS } from "./utils/vars"

export default function TaskList() {
  const navigate = useNavigate()
  const [filter, setFilter] = useState("1")
  const tasks = useTaskList()
  const dropdown = Object.values(TASK_STATUS)
    .filter((item) => typeof item === "number")
    .map((item) => ({
      value: (item as unknown as number).toString(),
      label: TASK_STATUS[item as number]
    }))
    .concat([
      {
        value: "6",
        label: "All"
      }
    ])

  const onClickCreateTask = () => {
    navigate("/tasks/create")
  }

  return (
    <section className="m-5">
      <HeaderMenu title="HOME | TASK LIST" />
      <section className="flex justify-between items-center my-3">
        <Select
          className="max-w-[200px]"
          placeholder="Select Status"
          options={dropdown ?? []}
          value={
            filter
              ? dropdown.find((item) => item.value === filter?.toString())
              : null
          }
          onChange={(e) => {
            if (e?.value) {
              setFilter(e?.value)
            }
          }}
        />

        <Button className="bg-primary" onClick={onClickCreateTask}>
          CREATE NEW TASK
        </Button>
      </section>

      <TableTaskList tasks={tasks} />
    </section>
  )
}
