import { Fragment } from "react"
import { ActionButton, Button, Modal } from "components"
import { FormSection } from "../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { ActionType, CreateSubGroupTask } from "../../types"
import { useApi, useToggle } from "utils"
import { validationSchema, defaultValues } from "../../utils"

export function Update({ data, refetch }: ActionType) {
  const { isActive, toggle } = useToggle(false)
  const api = useApi()

  const onSubmit = (value: CreateSubGroupTask) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/sub-group-task/edit?mr_group_sub_id=${data?.mr_group_sub_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => {
        toggle()
        refetch && refetch()
        toggle()
      })
      .catch(() => { })
      .finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: defaultValues(data!),
    resolver: yupResolver(validationSchema)
  })

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive && 
        <Modal isOpen title="UPDATE SUB GROUP TASK" closeModal={toggle}>
          <FormProvider {...methods}>
            {/* @ts-ignore */}
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Modal.Body>
                <FormSection action="UPDATE" />
              </Modal.Body>

              <Modal.Footer className="text-end">
                <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      }
    </Fragment>
  )
}